import { Client } from '@/types/client'
import { Message } from '@/types/messages'
import { messages } from '@ownesthq/owalt'
import { Commit, Dispatch } from 'vuex'
import {MessageDto} from "@/module/messageDto";

interface LastCollectionMessage {
  message: {
    createdAt: string,
    isEncrypted: boolean,
    isSeen: boolean,
    seenAt: string,
    text: string
  },
  nftCollection: {
    _id: string
    client: Client
    icon: string
    name: string
    slug?: string
  }
}

interface MessagesState {
  unreadMessagesCount: number
  lastUnreadMessage: null | Message

  // collections messages view
  currentCollectionsMessagesPage: null | number // for list of all messages by collection
  collectionsMessages: LastCollectionMessage[] // list of all collections with last received message [{message, nftCollection}]
  totalCollectionMessages: null | number // total collections that have messages

  messagesByCollection: {
    [collectionId: string]: {
      lastMessageDate: Date | null,
      messages: Message[],
      isThereStillPreviousMessages: boolean,
      newMessagesIdByWebSocket: string[]
    }
  }
  clients: {
    [clientId: string]: {
      name: string
      logo: string
    }
  }
  currentClientId: null | string
  requestController: null | AbortController // use to cancel a request in progress
  isFetchingMessages: boolean
}

const initialState: MessagesState = {
  unreadMessagesCount: 0,
  lastUnreadMessage: null,

  currentCollectionsMessagesPage: null,
  collectionsMessages: [],
  totalCollectionMessages: null,

  messagesByCollection: {},
  clients: {},

  currentClientId: null,
  requestController: null,

  isFetchingMessages: false
}

const collectionsPerPage = 20
const messagesPerPage = 40

export default {
  namespaced: true,
  state: () => (initialState),

  mutations: {
    saveCollectionsLastMessages(state: MessagesState, { messages = [], page = 0, total, isForceRefresh, clientId }: { messages: any[], page: number, total: number, isForceRefresh?: boolean, clientId?: string }) {
      if (isForceRefresh) state.collectionsMessages = []
      
      state.collectionsMessages = [...state.collectionsMessages, ...messages]
      state.currentCollectionsMessagesPage = page
      state.totalCollectionMessages = total
      state.currentClientId = clientId || null
    },
    saveCollectionMessages(state: MessagesState, { collectionId, messages = [], isForceRefresh, isNewMessages }: { collectionId: string, messages: any[], lastMessageDate: Date, isForceRefresh?: boolean, isNewMessages?: boolean }) {
      if (isForceRefresh) state.messagesByCollection[collectionId] = {
        messages: [],
        lastMessageDate: null,
        isThereStillPreviousMessages: true,
        newMessagesIdByWebSocket: []
      }
     
      if (!messages.length) {
        state.messagesByCollection[collectionId].isThereStillPreviousMessages = false
        return
      }

      const doesCollectionExists = !!state.messagesByCollection[collectionId]

      if (doesCollectionExists) {
        if (isNewMessages) {
          state.messagesByCollection[collectionId].messages = [...messages, ...state.messagesByCollection[collectionId].messages]
        } else {
          state.messagesByCollection[collectionId].messages = [...state.messagesByCollection[collectionId].messages, ...messages]
          state.messagesByCollection[collectionId].lastMessageDate = messages[messages.length - 1].createdAt
          if (messages.length < messagesPerPage) state.messagesByCollection[collectionId].isThereStillPreviousMessages = false
        }
      } else {
        state.messagesByCollection[collectionId] = {
          messages,
          lastMessageDate: messages[messages.length - 1].createdAt,
          isThereStillPreviousMessages: messages.length < messagesPerPage ? false : true,
          newMessagesIdByWebSocket: []
        }
      }
    },
    updateCollectionMessagesWithNewMessage(state: MessagesState, { collectionId, message }: { collectionId: string, message: any }) {
      const collectionMessages = state.messagesByCollection[collectionId]
      if (!collectionMessages) return

      collectionMessages.messages = [message, ...collectionMessages.messages]
      collectionMessages.newMessagesIdByWebSocket = [...collectionMessages.newMessagesIdByWebSocket, message._id]
    },
    setUnreadMessagesCount(state: MessagesState, count: number) {
      state.unreadMessagesCount = count
    },
    setLastUnreadMessage(state: MessagesState, message: any) {
      state.lastUnreadMessage = message
    },
    setRequestController(state: MessagesState, controller: AbortController) {
      state.requestController = controller
    },
    setRefreshingState(state: MessagesState, status: boolean) {
      state.isFetchingMessages = status
    },
    saveClient(state: MessagesState, {collectionId, client}: {collectionId: string, client: Client}) {
      state.clients[collectionId] = {
        name: client.name,
        logo: client.logo || ''
      }
    },
    reset(state: MessagesState) {
      state.unreadMessagesCount = 0
      state.lastUnreadMessage = null
      state.currentCollectionsMessagesPage = null
      state.collectionsMessages = []
      state.totalCollectionMessages = null
      state.messagesByCollection = {}
      state.requestController = null
      state.currentClientId = null
      state.clients = {}
    }
  },

  actions: {
    /**
     * @name getLastUnreadMessagesAndUnreadMessagesCount
     * @description
     * retrive two things:
     * - the last received and unread message
     * - the total count of unread messages
     */
    async getLastUnreadMessagesAndUnreadMessagesCount({ commit }: { commit: Commit }) {
      const { message, count }: { message: any, count: number } = await messages.getLastUnreadMessage()
      console.log("Last Unread Message: ", message);
      console.log("Unread Messages Count: ", count);
      commit('setUnreadMessagesCount', count)
      commit('setLastUnreadMessage', message)
    },

    async getCollectionsLastMessages({ state, commit }: { state: MessagesState, commit: Commit }, { page = 0, isForceRefresh, space }: { page: number, isForceRefresh?: boolean, space?: any }) {
      // stop current request before starting a new one (usefull for fetching pages with a searchName param while user is typing)

      if (state.requestController) state.requestController.abort()

      const clientId = space?._id || null
      const nftCollections = space?.nftCollections || [];
      if (clientId !== state.currentClientId) isForceRefresh = true

      console.log("Current client Id: ", clientId);

      const hasAlreadyTheRequestedPage = state.currentClientId === clientId && ((state.currentCollectionsMessagesPage !== null && state.currentCollectionsMessagesPage >= page && !!state.collectionsMessages?.length) || state.totalCollectionMessages === state.collectionsMessages.length)

      if (!isForceRefresh && hasAlreadyTheRequestedPage) {
        console.log("page already in store, force refresh display with current data");
        return {}
      }

      try {
        const controller = new AbortController()
        commit('setRequestController', controller)
        const res = await messages.getMessages({ perPage: collectionsPerPage, page, clientId, signal: controller.signal })
        // WIP : Figure out why it comes out empty when the request isn't
        if (nftCollections.length > 0 || clientId ) {
          // filter messages that are from a collection that is in the space
          for (let i = res.messages.length - 1; i >= 0; i--) {
            if (!nftCollections.includes(res.messages[i].nftCollection._id)) {
              res.messages.splice(i, 1)
              res.count--
            }
          }
        }

        const messagesArray = res.messages.map((message: any) => new MessageDto(message))
        const count = res.count
        commit('saveCollectionsLastMessages', { messages: messagesArray, page, total: count, isForceRefresh, clientId })
        return { messages: messagesArray, count }
      } catch (error: any) {
        console.error(error)
      }
    },

    async getCollectionsLastMessagesNextpage({ state, dispatch }: { state: MessagesState, dispatch: Dispatch }, {clientId}: {clientId?: string}) {
      if (state.currentCollectionsMessagesPage === null) return
      const nextPage = state.currentCollectionsMessagesPage + 1
      const res = await dispatch('getCollectionsLastMessages', { page: nextPage, clientId })
      return res
    },

    /**
     * @name getCollectionMessages
     * @description
     * retrieve [] of messages that are older than the last message date
     * if no message date given, it gets the last messages
     */
    async getCollectionMessages({ state, commit }: { state: MessagesState, commit: Commit }, { collectionId, isForceRefresh, isFirstFetch, firstMessageDate }: { collectionId: string, isForceRefresh?: boolean, isFirstFetch?: boolean, firstMessageDate?: Date }) {
      const lastMessageDate = state.messagesByCollection[collectionId]?.lastMessageDate || null
      
      // no more old messages to fetch OR mount state of chat when we already have recent messages in store
      if (!isForceRefresh && ((state.messagesByCollection[collectionId] && state.messagesByCollection[collectionId].isThereStillPreviousMessages === false) || (isFirstFetch && lastMessageDate))) {
        return []
      }
      
      try {
        const response  = await messages.getCollectionMessages({ collectionId, perPage: messagesPerPage, currentPage: 0})
        if (!response.messages) return []

        commit('saveCollectionMessages', { collectionId, messages: response.messages, isForceRefresh, isNewMessages: !!firstMessageDate })
        commit('saveClient', { collectionId, client: response.messages[0].fromClient })
        return { collectionMessages: response.messages }
      } catch (error: any) {
        console.log("Error fetching collection messages")
        console.log(error)
        return []
      }
    },

    async updateSeenMessages({ state }: { state: MessagesState }, unseenMessagesIds: string[]) {
      console.log("Update Seen Messages");
      await messages.updateSeenMessages(unseenMessagesIds)
    },

    async handleNewReceivedMessage({ state, commit }: { state: MessagesState, commit: Commit }, { message, count }: { message: any, count: number }) {
      const collectionId = message?.nftCollection?._id
      const collectionMessages = state.messagesByCollection[collectionId]

      if (collectionMessages) {
        // do not push received message if we do not already have messages, we will get them on chat opening with regular messages fetch
        commit('updateCollectionMessagesWithNewMessage', { collectionId, message })
        commit('setUnreadMessagesCount', count)
      }
    }
  }
}
