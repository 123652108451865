import { Nft } from "@/plugins/client"
import { user } from "@ownesthq/owalt"
import { Commit } from "vuex"

interface BadgesState {
  badges: Nft[]
  total: number
  isFetchingBadges: boolean
}

export default {
  namespaced: true,

  state: (): BadgesState => ({
    badges: [],
    total: 0,
    isFetchingBadges: false,
  }),

  mutations: {
    setIsFetching(state: BadgesState, bool: boolean) {
      state.isFetchingBadges = bool
    },

    fetchBadgesSuccess(state: BadgesState, { badges, total }: { badges: Nft[]; total: number }
    ) {
      state.badges = badges
      state.total = total
      state.isFetchingBadges = false
    },

    reset(state: BadgesState) {
      state.badges = []
      state.total = 0
      state.isFetchingBadges = false
    }
  },

  actions: {
    async fetchBadges({ commit, state }: { commit: Commit; state: BadgesState }) {
      if (state.isFetchingBadges) return

      commit("setIsFetching", true)

      try {
        const { count, badges } = await user.getBadges()
        commit("fetchBadgesSuccess", { badges, total: count })

        return { total: count, products: badges }
      } catch (error) {
        console.log(error)
      } finally {
        commit("setIsFetching", false)
      }
    }
  },
}
