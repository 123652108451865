export default {
  "pages": {
    "title": {
      "my-collections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My collections"])},
      "activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity"])},
      "messaging": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messaging"])},
      "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
      "spaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spaces"])},
      "gallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gallery"])}
    },
    "login": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The easiest application to manage your Digital Collectibles ⭐️"])}
    }
  },
  "buttons": {
    "login": {
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let's go"])}
    }
  },
  "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept"])},
  "contact-permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We need your permission to access your contacts."])},
  "share-contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share your contacts"])},
  "share-my-contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share my contacts"])},
  "do-not-share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do not share"])},
  "address-copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address copied to clipboard"])},
  "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
  "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copied"])},
  "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "see-all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See all"])},
  "no-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No data to display for this section."])},
  "enter-received-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the code received on your phone"])},
  "invalid-code-try-again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid code. Please try again."])},
  "resend-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resend the code"])},
  "retry-in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can try again in:"])},
  "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous"])},
  "received-by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sent to"])},
  "sent-by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Received from"])},
  "recipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient :"])},
  "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summary"])},
  "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
  "fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction fees :"])},
  "biometric-authentication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biometric authentication"])},
  "confirm-identity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please confirm your identity"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "collections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collections"])},
  "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
  "no-collection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No collection to display"])},
  "accept-by-clicking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By clicking on \"Let's go\" you accept the"])},
  "privacy-policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["privacy policy"])},
  "and-the": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and the"])},
  "GCU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GCU"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description :"])},
  "collection": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collection :"])},
    "blockchain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blockchain"])},
    "supply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elements"])},
    "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creator"])}
  },
  "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created by :"])},
  "receivedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Received on :"])},
  "tokenId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serial number :"])},
  "attributes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attributes"])},
  "date-unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date unknown"])},
  "receive-nft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receive a Digital Collectible"])},
  "show-qr-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show this QR code to receive a digital collectible"])},
  "select-nft-to-send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Which collectible(s) would you like to send?"])},
  "no-nft-yet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have any items yet."])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
  "is-sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It's sent!"])},
  "will-receive-soon": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["It is sent, the recipient will receive the collectible shortly"]), _normalize(["It is sent, the recipient will receive the collectibles shortly"])])},
  "contact-permission-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We need your permission to access your contacts. You can then send a collectible to someone in your address book without having to enter their phone number manually."])},
  "send-nft-amount": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Send my collectible to"]), _normalize(["Send my collectibles to"])])},
  "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts"])},
  "blockchain-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blockchain address"])},
  "enter-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter an address"])},
  "enter-phone-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a phone number"])},
  "standard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard"])},
  "fast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fast"])},
  "immediate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immediate"])},
  "scan-qr-please": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please scan the QRCode."])},
  "no-recent-activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No recent activity at the moment."])},
  "sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sent"])},
  "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Received"])},
  "updated-on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated on :"])},
  "congrats-received-nft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congratulations on receiving an NFT! 🎉"])},
  "discover-nft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discover my NFT"])},
  "authorize-biometrics-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To ensure the security of your Owalt and to connect you more quickly, we need you to authorise biometric authentication (fingerprint or facial recognition)."])},
  "authorize-biometrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allow biometric authentication"])},
  "my-wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My wallet"])},
  "recently-received-nft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Received recently"])},
  "no-nft-received-recently": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have not received anything recently"])},
  "receive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receive"])},
  "account-deletion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account deletion"])},
  "account-deletion-are-you-sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete your account?"])},
  "thirty-days-before-account-deletion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have 30 days to recover it before it is permanently deleted."])},
  "lost-nfts": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Collectibles will be lost"]), _normalize(["Collectibles will be lost"])])},
  "account-deletion-sure-about-nfts-lost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you really sure you want to delete your account and lose all the items in your wallet?"])},
  "keep-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I keep my account"])},
  "i-delete-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I delete my account"])},
  "sorry-see-you-go": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry to see you go"])},
  "account-soon-deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your account will be deleted soon"])},
  "account-deletion-thirty-days-left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have 30 days to recover it, after which it will be permanently deleted."])},
  "errors": {
    "errorOccured": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error has occurred, please try again"])},
    "oops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops...!"])}
  },
  "my-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My account:"])},
  "safety": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Safety:"])},
  "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support:"])},
  "terms-and-conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms and conditions of use"])},
  "privacy-policy-setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
  "contact-authorization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authorisation contacts"])},
  "biometric-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biometric data"])},
  "reconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reconnect"])},
  "do-not-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do not allow"])},
  "contact-permission-required-example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Example: You want to send your collectible to a friend whose phone number you have, but don't know by heart? Simply select his name from your contact list to send him."])},
  "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
  "actions": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])}
  },
  "add-memory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a picture"])},
  "authenticity-certificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificate of authenticity and warranty"])},
  "repair-request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request for repair"])},
  "my-pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My pictures"])},
  "block-nft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Block the NFT"])},
  "loss-or-theft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In case of loss or theft"])},
  "make-opposition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Making an opposition"])},
  "declare-nft-lost": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you want to report the NFT of your ", _interpolate(_named("nftName")), " lost or stolen? The NFT will be blocked."])},
  "add-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add details"])},
  "sure-make-opposition": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Are you really sure you want to block the NFT of your ", _interpolate(_named("nftName")), "?"])},
  "it-is-a-mistake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is a mistake"])},
  "yes-block-nft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, I want to block it"])},
  "withdraw-opposition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdraw my opposition"])},
  "nft-will-be-unblocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The NFT will be unlocked and the loss/theft report removed."])},
  "nft-blocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This NFT is blocked"])},
  "private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Private"])},
  "public": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public"])},
  "no-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have not received any message"])},
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading..."])},
  "page-number": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["page"]), _normalize([" pages"])])},
  "show-all-pages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show all pages"])},
  "take-picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Take a picture"])},
  "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validate"])},
  "give-memory-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Give your picture a name"])},
  "file-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File title"])},
  "add-file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a file"])},
  "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Today"])},
  "week-of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Week of"])},
  "no-gallery-picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have not yet registered a photo for this NFT"])},
  "from-gallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From my gallery"])},
  "email": {
    "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your email"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Associate an email to your Owalt account"])},
    "myAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My account :"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mail of confirmation"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It's send, you will receive a confirmation email."])},
    "myEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My mail :"])},
    "errorConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error has occurred, your email could not be validated"])},
    "alreadyUsed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This mail is already used"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])}
  },
  "enter-received-code-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the code received by email"])},
  "confirmMyEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
  "warrantyCertificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warranty certificate"])},
  "manufacturerWarranty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manufacturer's warranty: 2 years"])},
  "proofOfOwnership": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proof of ownership"])},
  "detainedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detained by :"])},
  "ref": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réf :"])},
  "model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Model :"])},
  "dateOfPurchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of purchase :"])},
  "proofOfAuthenticity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proof of authenticity"])},
  "holderPassport-Halchimy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are the passport holder"])},
  "blocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blocked"])},
  "exchange-with-client": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Exchange with ", _interpolate(_named("client"))])},
  "messaging": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messaging"])},
  "history": {
    "transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NFT transfer"])},
    "opposition-Halchimy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passport blockage"])},
    "repair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repair request"])},
    "addImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adding a public photo"])},
    "endRepair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repair finished"])},
    "button": {
      "seeRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See the request"])},
      "seePhoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See the photo"])}
    },
    "withdrawOpposition-Halchimy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passport unlocking"])},
    "creation-Halchimy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creation of the passport"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["History"])},
    "transfer-Halchimy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passport transfer"])},
    "creation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creation of the NFT"])},
    "withdrawOpposition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NFT unlocking"])},
    "opposition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blocking of the NFT"])}
  },
  "product": {
    "details": {
      "buttons": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
        "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["History"])},
        "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])}
      },
      "label": {
        "tokenId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serial number"])},
        "tokenId-Halchimy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digital passport"])}
      }
    }
  },
  "wish-you-delete-picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Would you like to delete this photo?"])},
  "are-you-sure-delete-picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This action is irreversible. Are you sure you want to delete this photo?"])},
  "picture-edition-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pictures"])},
  "private-picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Private"])},
  "public-picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public"])},
  "picture-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Picture type"])},
  "add-picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a picture"])},
  "repair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repair"])},
  "repairRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maintenance request"])},
  "repairInProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maintenance in progress"])},
  "repairFinished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maintenance finished"])},
  "searchCity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search a city"])},
  "sendRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send the request"])},
  "deposeMyProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I deposal my product"])},
  "finishedRepair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repair finished"])},
  "requestSend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request send"])},
  "appointmentRepairCenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appointment at the indicated repair center"])},
  "repairCenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repair center :"])},
  "addDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a description of the problem"])},
  "with-certified-repairer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With a certified repairer"])},
  "see-my-current-request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See my current request :"])},
  "authenticity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authenticity :"])},
  "qr-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR Code"])},
  "submitProductModal": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disposal my product"])},
    "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You delegate the responsibility to <strong>", _interpolate(_named("store")), "</strong>, you must present the proof of ownership to claim your product"])},
    "deposeMyProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I dispose my product"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No, I am not at the center yet"])}
  },
  "back-home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to home"])},
  "mint-in-progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mint in progress..."])},
  "changeMyEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change my email"])},
  "is-confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It's confirmed"])},
  "mint-on-my-wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mint on my wallet"])},
  "minted-on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minted on"])},
  "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owner"])},
  "scan-product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan the QR code of a product"])},
  "scan-qrcode-target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aim at the QR code to select the product"])},
  "scan-qrcode-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The QR code is not recognized by the application."])},
  "addressBC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blockchain Addresses"])},
  "contact-unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Unknown"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "not-specified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not specified"])},
  "notifications-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Push Notifications"])},
  "add-to-contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to contact"])},
  "add-a-contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a contact"])},
  "give-contact-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the name under which to register the contact"])},
  "contact-added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact added"])},
  "contact-added-name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " has been added to your address book"])},
  "forms": {
    "fields": {
      "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
      "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])}
    }
  },
  "serialNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serial number :"])},
  "externalWallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["External wallet"])},
  "contact-search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type a name, phone number."])},
  "refuse-fingerprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do not use"])},
  "certified-ethereum-blockchain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certified on the Ethereum blockchain"])},
  "block-nft-Halchimy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Block the passport"])},
  "declare-nft-lost-Halchimy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you want to report your ", _interpolate(_named("nftName")), " passport lost or stolen? The passport will be blocked."])},
  "sure-make-opposition-Halchimy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Are you really sure you want to block your ", _interpolate(_named("nftName")), "'s passport?"])},
  "nft-blocked-Halchimy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This passport is blocked"])},
  "no-gallery-picture-Halchimy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have not yet registered a photo for this passport"])},
  "nft-will-be-unblocked-Halchimy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The passport will be unlocked and the loss/theft report removed."])},
  "discover-nft-Halchimy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discover my passport"])},
  "congrats-received-nft-Halchimy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congratulations on receiving a digital passport! 🎉"])},
  "send-nft-amount-Halchimy": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Send my passport"]), _normalize(["Send my passports "])])},
  "will-receive-soon-Halchimy": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Sent, recipient will receive passport shortly"]), _normalize(["Sent, recipient will receive passports shortly"])])},
  "tokenId-Halchimy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digital passport :"])},
  "holderPassport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are the holder of the NFT"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
  "search-contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for a contact or a phone number"])},
  "public-pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public pictures"])},
  "cancel-repair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel the request"])},
  "cancel-repair-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Would you like to delete this maintenance request?"])},
  "cancel-repair-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This action is irreversible."])},
  "accept-by-clicking-received-nft": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["By clicking on \"", _interpolate(_named("buttonText")), "\" you accept the"])},
  "recaptcha": {
    "conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This application is protected by reCAPTCHA and the Google\n          <a href=\"https://policies.google.com/privacy\" target=\"_blank\">Privacy Policy</a> and\n          <a href=\"https://policies.google.com/terms\" target=\"_blank\">Terms of Service</a> apply. "])}
  },
  "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retry"])},
  "send-invalid-address-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred during the sending process. It could be that the recipient's phone number, address or ENS name does not exist."])},
  "pending-transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In transfer"])},
  "performed-service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Performed service"])},
  "service-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service type"])},
  "send-invalid-address-typing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid address"])},
  "weeks-ago": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["This week"]), _normalize(["A week ago"]), _normalize([_interpolate(_named("count")), " weeks ago"])])},
  "mint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mint"])},
  "collectibles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My collectibles"])},
  "no-nft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No NFTs to display"])},
  "new-messages": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " New message"]), _normalize([_interpolate(_named("count")), " New messages"])])},
  "concerned-nfts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NFTs concerned"])},
  "today-at": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Today at ", _interpolate(_named("time"))])},
  "nfts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NFTs"])},
  "profile": {
    "my-badges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My badges"])}
  },
  "no-badges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Badges to display"])},
  "exchanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exchanges"])},
  "new-user-alias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New username"])},
  "spaces": {
    "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Or choose a Space"])},
    "selectmain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access main wallet"])}
  },
  "congrats-received-nft-homeety": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congratulations on receiving an NFT! 🎉"])},
  "discover-nft-homeety": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discover my NFT"])},
  "send-nft-amount-homeety": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Send my NFT"]), _normalize(["Send my NFTs"])])},
  "will-receive-soon-homeety": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["It is sent, the recipient will receive the NFT shortly"]), _normalize(["It is sent, the recipient will receive the NFTs shortly"])])},
  "block-nft-homeety": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Block the NFT"])},
  "load-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load more"])},
  "update-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It's time to update!"])},
  "update-available-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A new version of our app is available in the store."])},
  "GCU-update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our General Terms and Conditions are changing"])},
  "GCU-update-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To be able to continue accessing your Owalt account, please accept them."])},
  "read-GCU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read the terms and conditions"])},
  "privacy-policy-update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our privacy policy is evolving"])},
  "privacy-policy-update-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To be able to continue accessing your Owalt account, please accept it."])},
  "read-privacy-policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read the privacy policy"])},
  "actuality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["News"])},
  "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or"])},
  "show-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show more"])},
  "resend-code-whatsap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "enter-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter an email"])},
  "owalt": {
    "confirm": {
      "delete": {
        "communication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
      }
    }
  },
  "downloadAndInstall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download and install"])}
}