import { news as newsAPI } from "@ownesthq/owalt";
import { News } from "@/plugins/client";
import { Commit, Dispatch } from 'vuex';


interface NewsState {
  news: News[],
}

const initialState = {
  news: [],
}

export default {
  namespaced: true,
  state: () => (initialState),

  mutations: {
    fetchNewsSuccess(state: NewsState, { news }: { news: News[] }) {
        state.news = news
    },
  },

  actions: {
    async fetchNews({ commit }: { commit: Commit }, spaceId: string ) {
      const response = await newsAPI.getNews(spaceId)
      console.log("Fetch News");
      console.log(response);
      commit('fetchNewsSuccess', { news: response })
    },
    async updateNewsUrlClickedCount({ commit }: { commit: Commit }, newsId: string ) {   
      try {        
        await newsAPI.updateUrlClicked(newsId)
      } catch (error) {
        console.log(error)
      }
    },
    async updateNewsViewCount({ commit }: { commit: Commit }, newsId: string ) {
      try {
         await newsAPI.updateViewCount(newsId)
      } catch (error) {
        console.log(error);
      }
    },
  },
}