import { Collection } from '@/plugins/client'
import { product, collection } from '@ownesthq/owalt'
import { Commit, Dispatch } from 'vuex'

interface CollectionState {
  collections: Collection[],
  currentPage: number | null,
  total: number | null,
  isFetching: boolean,
  requestController: null | AbortController // use to cancel a request in progress
  currentClientId: null | string // space client id selected
}

const initialState = {
  collections: [],
  currentPage: null,
  total: null,
  isFetching: false,
  requestController: null,
  currentClientId: null
}

const perPage = 20

export default {
  namespaced: true,
  state: () => (initialState),

  mutations: {
    setIsFetching(state: CollectionState, bool: boolean) {
      state.isFetching = bool
    },
    setRequestController(state: CollectionState, controller: AbortController) {
      state.requestController = controller
    },
    saveCollections(state: CollectionState, { items, total, page, isForceRefresh, clientId }: { items: Collection[]; total: number, page: number, isForceRefresh?: boolean, clientId?: string }) {
      if (isForceRefresh)
        state.collections = []

      state.collections = [...state.collections, ...(items.map(item => {
        return {
          ...item,
          slug: item.nftCollection.slug
        }
      }))]
      state.total = total
      state.currentPage = page
      state.currentClientId = clientId || null
    },
    resetState(state: CollectionState) {
      state.collections = []
      state.currentPage = null
      state.total = null
      state.isFetching = false
      state.requestController = null
      state.currentClientId = null
    }
  },

  actions: {
    async getCollections({ commit, state }: { state: CollectionState, commit: Commit }, { page = 0, searchedName, isForceRefresh, isAbleToSend = false, clientId, isNextPage = false, getAll = false }: { page: number, searchedName?: string, isForceRefresh?: boolean, isAbleToSend: boolean, clientId: string | null | undefined, isNextPage: boolean, getAll: false }) {
      
      if (state.isFetching && page !== 0) return

      // stop current request before starting a new one (usefull for fetching pages with a searchName param while user is typing)
      if (state.requestController) state.requestController.abort()

      commit('setIsFetching', true)
      clientId = clientId || null
      if (clientId !== state.currentClientId) isForceRefresh = true

      if (searchedName || getAll) {
        /**
         * DO NOT store data filtered collections
         * we only want to store collections by most recent receptionDate, not filtered ones
         * (display order and pagination do not match)
        */
        // TODO: MAYBE save collections by id though ? too see later
        try {
          const controller = new AbortController()
          commit('setRequestController', controller)
          const { collections, count } = await product.getUserCollections({ filters: { ableToSend: isAbleToSend, name: searchedName }, currentPage: page, perPage, clientId, getAll, signal: controller.signal })
          return { collections, count }
        } catch (error: any) {
          return
        } finally {
          commit('setIsFetching', false)
        }
      }

      const hasAlreadyTheRequestedPage = state.currentClientId === clientId && ((state.currentPage !== null && state.currentPage >= page && !!state.collections.length) || state.total === state.collections.length)

      if (!isForceRefresh && hasAlreadyTheRequestedPage) {
        // page already in store, force refresh display with current data
        commit('saveCollections', { items: state.collections, total: state.total, page: state.currentPage, isForceRefresh: true, clientId })
        commit('setIsFetching', false)

        // return all products if we request the first page
        if (!isNextPage) return { collections: state.collections, count: state.total }
        return {} // do not return collections if we already returned the last page, nothing new to display
      }

      try {
        const controller = new AbortController()
        commit('setRequestController', controller)
        const { collections, count } = await product.getUserCollections({ filters: { ableToSend: isAbleToSend }, currentPage: page, perPage, clientId, signal: controller.signal })
        commit('saveCollections', { items: collections, total: count, page: page, isForceRefresh, clientId })
        return { collections, count }
      } catch (error: any) {
        return
      } finally {
        commit('setIsFetching', false)
      }
    },
    async getCollection({ state }: { state: CollectionState }, { idOrSlug, isForceRefresh }: { idOrSlug: string, isForceRefresh?: boolean }) {
      const foundCollection = state.collections.find((collection: Collection) => collection._id === idOrSlug || collection.slug === idOrSlug)
      if (!isForceRefresh && foundCollection) return foundCollection
      // force refresh

      const collectionRes = await collection.getUserCollection({ collectionId: idOrSlug })

      // update nbr nft on collection
      if (collectionRes && foundCollection)
        foundCollection.count = collectionRes.count

      return collectionRes
    },

    async fetchNextCollections({ state, dispatch }: { state: CollectionState, dispatch: Dispatch }, { page = 0, searchedName = '', clientId, isAbleToSend = false }: { page?: number, isAbleToSend?: boolean, clientId?: string , searchedName?: string }) {
      let nextPage

      if (searchedName) {
        nextPage = page + 1
      } else {
        if (state.currentPage === null) return
        nextPage = state.currentPage + 1
      }

      return dispatch('getCollections', {
        page: nextPage,
        isNextPage: true,
        isAbleToSend,
        searchedName,
        clientId
      })
    },

    abortCurrentRequest({ state }: { state: CollectionState }) {
      // stop current request before starting a new one (usefull for fetching pages with a searchName param while user is typing)
      if (state.requestController) state.requestController.abort()
    },

    reset({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }) {
      // abort current request if there is one before reset
      dispatch('abortCurrentRequest')
      commit('resetState')
    }
  },
}