import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import moment from 'moment'

import { user } from '@ownesthq/owalt'

const { availableLanguagesISOCode, availableLanguages: languagesList } = require("../locales/availableLocales.js")

interface Language {
  ISOCode: string
  name: string
}

export default function languageManager(){
  const { locale } = useI18n()
  const availableLanguages = ref<Language[]>(availableLanguagesISOCode)

  const changeLanguage = (selectedLanguage: string) => {
    if (locale.value === selectedLanguage)
      return
    
    setLocale(selectedLanguage)
    window.localStorage.locale = selectedLanguage
    user.setSettings({ language: selectedLanguage })
  }

  const restoreLocaleFromLocalStorage = () => {
    const lastUsedLocale = window.localStorage.getItem('locale')

    if (lastUsedLocale && languagesList.includes(lastUsedLocale)) {
      setLocale(lastUsedLocale)
    } else {
      const navigatorLocale = navigator.language.substring(0, 2)
      const isLocaleAvailable = languagesList.includes(navigatorLocale)
      const localeToUse = isLocaleAvailable ? navigatorLocale : 'fr'
      setLocale(localeToUse)
    }
  }

  const setLocale = (selectedLanguage: string) => {
    locale.value = selectedLanguage
    moment.locale(selectedLanguage)
  }
  
  return {
    availableLanguages,
    changeLanguage,
    restoreLocaleFromLocalStorage
  }
}
