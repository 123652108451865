import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import authStore from './store/modules/auth'
import { axios, auth } from '@ownesthq/owalt'
import axiosLibrary from 'axios'
import VueMatomo from 'vue-matomo'
import OwaltUi from '@ownesthq/owalt-ui'
import { polyfillCountryFlagEmojis } from "country-flag-emoji-polyfill";
import VueLazyLoad from 'vue3-lazyload'

import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

// This will load a webfont called "Twemoji Country Flags" on relevant browsers to fix windows not displaying country flags
polyfillCountryFlagEmojis()

// to talk to the api locally with owalt package
axios.setBaseUrl(process.env.VUE_APP_API_BASE_URL)

let accessTokenGlobal: string = ''
let refreshCall: any = null

function handleRefreshToken(store: any, callback: any) {
  /* outdated code (handled by auth store now) */
  
  // if (store.state.auth.isUserRefreshing) {
  //   // refreshCall has already resolved
  //   if (!refreshCall || !refreshCall.then) {
  //     const chained = callback()
  //     refreshCall = chained
  //     return chained
  //   }

  //   // refreshCall has not been resolved yet
  //   // adds call if is refreshing OR if refresh has finished but is still depiling
  //   const chained = refreshCall.then(callback).catch((error: any) => Promise.reject(error))
  //   refreshCall = chained
  //   return chained
  // }


  const refreshingCall = store.dispatch('auth/refreshSessionTokens').then(({ accessToken, refreshToken }: { accessToken: string, refreshToken: string }) => {
    if (!accessToken || !auth.hasValidAccessToken() || !refreshToken) {
      store.commit('auth/resetTokens')
      return Promise.reject()
    }

    accessTokenGlobal = accessToken

    auth.setRefreshToken(refreshToken)
    auth.setAccessToken(accessToken)
    
    store.commit('auth/saveTokens', {
      accessToken,
      refreshToken
    })
    
    return Promise.resolve()
  }).catch((error: any) => {
    // refreshToken has failed
    store.dispatch("logout");
    router.push({ name: "login" })
    return Promise.reject(error)
  }).then(callback)

  refreshCall = refreshingCall
  return refreshingCall
}


/* ****************************************************
------------------ AXIOS INTERCEPTOR ------------------
***************************************************** */
axios.instance.interceptors.response.use(
  (response: any) => {
    return response
  },
  async (error: any) => {
    try {
      if (error.response?.status === 401) {
        return handleRefreshToken(store, () => {
          if (!accessTokenGlobal || !auth.hasValidAccessToken()) {
            store.commit('auth/resetTokens')
            return Promise.reject(error)
          }

          error.config.headers['Authorization'] = `Bearer ${accessTokenGlobal}`
          return axiosLibrary.request(error.config)
        })
      }

      return Promise.reject(error)
    } catch (error) {
      store.dispatch("logout");
      router.push({ name: "login" });
      return Promise.reject(error)
    }
  }
)

import i18n from './i18n'

const app = createApp(App).use(i18n)
app.use(OwaltUi).use(store).use(router)

// Matomo
// NOTE: to use matamo, use window._paq and add a `declare let window: any` at top of the <script> section of the view file
// ex: window._paq.push(['trackEvent', 'Home', 'Modal opened', 'Received NFT'])
app.use(VueMatomo, {
  host: process.env.VUE_APP_MATOMO_HOST,
  siteId: process.env.VUE_APP_MATOMO_SITE_ID,
  router: router,
})

// lazy loading images
app.use(VueLazyLoad, {
  loading: '',
  error: '',
  lifecycle: {
    loading: (el: any) => {
    },
    error: (el: any) => {
    },
    loaded: (el: any) => {
    }
  }
})

// Sentry
Sentry.init({
  app,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ['dev-app.owalt.io', 'staging-app.owalt.io', 'preprod-app.owalt.io', 'app.owalt.io']
    }),
  ],
  tracesSampleRate: 1.0,
});

app.mount('#app')

console.log({app})

app.directive('scroll', {
  mounted: (el, binding) => {
    const f = (evt: any) => {
      if (binding.value(evt, el)) {
        el.removeEventListener('scroll', f)
      }
    }
    el.addEventListener('scroll', f)
  }
})

if (process.env.NODE_ENV === 'development') localStorage.debug = 'socket.io-client:socket'