import { isIosApplication } from "@/utils/platforms";

declare let window: any

import router from '@/router/index'

export const FCMOnMessageReceived = () => {    
    FCMHasPermission().then((hasPermission: boolean) => {
        if (!hasPermission) return

        FirebasePlugin.onMessageReceived((message: any) => {
            if (!message.tap) return

            const action = JSON.parse(message.action)
            if (action.name === 'product_view') {
                router.push({ name: 'home', params: { receivedNFT: action.id} })
            }
            if (action.name === 'message_view') {
                router.push({ name: 'messaging', params: { _id: action.id} })
            }
        }, (error: any) => {
            console.log(error)
        })
    })
}

const firebaseSetup = () => {
    return new Promise((resolve, reject) => {
        FCMOnMessageReceived()
        FirebasePlugin.onTokenRefresh(async (fcmToken: any) => {
            resolve(fcmToken);
        }, (error: any) => {
            reject(error)
        });
    })
}

const FCMHasPermission = (): Promise<boolean> => {
    return new Promise((resolve, reject) => {
        FirebasePlugin.hasPermission((hasPermission: any) => {
            resolve(hasPermission)
        }, (error: any) => {
            reject(error)
        })
    })
}

const FCMGrantPermission = (): Promise<boolean> => {
    return new Promise((resolve, reject) => {
        FirebasePlugin.grantPermission((hasPermission: any) => {
            resolve(hasPermission)
        }, (error: any) => {
            reject(error)
        })
    })
}

export const FCMRegister = async () => {
    if (isIosApplication()) {
        try {
            let hasPermission = await FCMHasPermission()
            if (!hasPermission) hasPermission = await FCMGrantPermission()
            if (hasPermission) return firebaseSetup();
        } catch (error) {
            console.error("FirebasePlugin grantPermission error", error);
        }
    } else {
        return firebaseSetup();
    }
}

export const FCMUnregister = () => {
    FirebasePlugin.unregister();
}
