const availableLanguagesISOCode = [
  {
    ISOCode: 'fr',
    name: 'Français'
  },
  {
    ISOCode: 'en',
    name: 'English'
  },
  {
    ISOCode: 'es',
    name: 'Español'
  },
  {
    ISOCode: 'pt',
    name: 'Português'
  }
]

const availableLanguages = availableLanguagesISOCode.reduce((acc, curr) => {
  return [...acc, curr.ISOCode]
}, [])

module.exports = {
  availableLanguagesISOCode,
  availableLanguages
}
