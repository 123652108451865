import { PhoneNumber } from "libphonenumber-js";
import { computed, ref } from "vue";
import { useStore } from "vuex";

declare let window: any

export default function loginManager() {
  const isFocus = ref<boolean>(false)
  const phoneNumber = ref<PhoneNumber | undefined>(undefined);
  const email = ref<string>('')
  const store = useStore()

  const isTermsAndConditionsModalShown = ref<boolean>(false);
  const isPrivacyPolicyModalShown = ref<boolean>(false);
  const privacyPolicyText = ref<string>("");
  const termsAndConditionsText = ref<string>("");

  const isPhoneNumberValid = computed(() => {
    if (!phoneNumber.value) return false;

    return phoneNumber.value.isValid();
  });

  const isEmailValid = computed(() => {
    if (!email.value) return false;
    
    const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return regexp.test(email.value)
  })

  function handleFocus() {
    isFocus.value = true
  }

  function handleBlur() {
    isFocus.value = false
  }

  function handlePhoneNumberUpdate(value: PhoneNumber): void {
    phoneNumber.value = value;
  }

  function handleEmailUpdate(value: string): void {
    email.value = value;
  }

  const settingBiometric = computed(() => {
    return store.state.permissions.isBiometricsAllowed
  })

  const handleOpenTermsAndConditionsModal = (): void => {
    window._paq.push(['trackEvent', 'Login', 'Modal opened', 'Terms and conditions'])
    isTermsAndConditionsModalShown.value = true;
  };

  const handleCloseTermsAndConditionsModal = (): void => {
    isTermsAndConditionsModalShown.value = false;
  };

  const handleOpenPrivacyPolicyModal = (): void => {
    window._paq.push(['trackEvent', 'Login', 'Modal opened', 'Privacy policy'])
    isPrivacyPolicyModalShown.value = true;
  };

  const handleClosePrivacyPolicyModal = (): void => {
    isPrivacyPolicyModalShown.value = false;
  };

  return {
    handleFocus,
    handleBlur,
    handlePhoneNumberUpdate,
    handleEmailUpdate,
    handleOpenTermsAndConditionsModal,
    handleCloseTermsAndConditionsModal,
    handleOpenPrivacyPolicyModal,
    handleClosePrivacyPolicyModal,
    isPhoneNumberValid,
    isEmailValid,
    isFocus,
    phoneNumber,
    email,
    privacyPolicyText,
    termsAndConditionsText,
    isTermsAndConditionsModalShown,
    isPrivacyPolicyModalShown
  }
}