import { Collection } from '@/plugins/client'
import { product } from '@ownesthq/owalt'
import { Commit, Dispatch } from 'vuex'
import router from '../../router'
import { Nft } from '@/plugins/client'


// interface Product {
// list: any[]
// currentPage: number | null
// total: number | null
// lastFetch: Date | null
// isFetchingProducts: boolean
// }

// interface ProductsByCollection {
//   // [collectionId: string]: Product
// }

interface ProductState {
  products: Nft[]
  isFetchingProducts: boolean
  currentPage: null | number
  currentClientId: null | string // space client id selected
  total: null | number
  requestController: null | AbortController // use to cancel a request in progress

  // productsByCollection: ProductsByCollection
  // shouldOnlyShowAbleToSend: boolean

  // lastReceivedProducts: Array<any>
  // lastReceivedProductsCurrentPage: null | number
  // lastFetchOfLastReceivedProducts: Date | null

  // totalProductsCount: number | null

  // isFetchingTotalProductsCount: boolean
  // isFetchingLastReceivedProducts: boolean
}

const perPage = 20

export default {
  namespaced: true,

  state: (): ProductState => ({
    products: [],
    isFetchingProducts: false,
    currentPage: null,
    total: null,
    requestController: null,
    currentClientId: null,
  }),

  mutations: {
    fetchProductsSuccess(state: ProductState, { products, page, total, isForceRefresh, clientId }: { products: Nft[], page: number, total: number, isForceRefresh?: boolean, clientId?: string }) {
      if (isForceRefresh) state.products = []

      state.products = [...state.products, ...products]
      state.currentClientId = clientId || null
      state.currentPage = page
      state.total = total
    },
    setIsFetching(state: ProductState, bool: boolean) {
      state.isFetchingProducts = bool
    },
    setRequestController(state: ProductState, controller: AbortController) {
      state.requestController = controller
    },
    resetState(state: ProductState) {
      state.products = []
      state.isFetchingProducts = false
      state.currentPage = null
      state.total = null
      state.requestController = null
      state.currentClientId = null
    }
  },

  actions: {
    async getProductsFromCollection({ commit, state }: { commit: any, state: ProductState }, { collectionIdOrSlug, ableToSend = false, page = 0, searchedName, isForceRefresh }: { collectionIdOrSlug: string; ableToSend: boolean; page: number; searchedName?: string, isForceRefresh?: boolean }) {
      // stop current request before starting a new one (usefull for fetching pages with a searchName param while user is typing)
      if (state.requestController) state.requestController.abort()

      try {
        const controller = new AbortController()
        commit('setRequestController', controller)
        const data = await product.productsFromCollection(collectionIdOrSlug, { ableToSend, name: searchedName }, page, perPage, controller.signal)
        return data
      } catch (error: any) {
        console.log(error)
      }
    },

    async fetchNextProductsFromCollection ({ dispatch, state }: { dispatch: Dispatch, state: ProductState }, { collectionIdOrSlug, ableToSend = false, page, searchedName, isForceRefresh }: { collectionIdOrSlug: string; ableToSend: boolean; page: number; searchedName?: string, isForceRefresh?: boolean }) {
      const  nextPage = page + 1

      return dispatch('getProductsFromCollection', {
        collectionIdOrSlug,
        page: nextPage,
        searchedName,
        ableToSend,
      })
    },

    async getProductById({ state }: { state: ProductState }, { productId, isForceRefresh = false }: { productId: string, isForceRefresh: boolean }) {
      const foundProduct = state.products.find((product: any) => product._id === productId)
      if (foundProduct && !isForceRefresh) return foundProduct
      const { data } = await product.productById(productId)
      return data
    },

    async fetchProductsByCollection({ state, commit }: { state: ProductState, commit: any }, { collectionIdOrSlug, searchedName, ableToSend = false, page = 0 }: { collectionIdOrSlug: string; searchedName: string; ableToSend: boolean; page: number }) {
      if (state.isFetchingProducts && page !== 0) return // allow new request if previous is not finished only is we ask for page 0

      // stop current request before starting a new one (usefull for fetching pages with a searchName param while user is typing)
      if (state.requestController) state.requestController.abort()

      commit('setIsFetching', true)

      if (searchedName) {
        try {
          const controller = new AbortController()
          commit('setRequestController', controller)
          const { products, count } = await product.productsFromCollection(collectionIdOrSlug, { ableToSend, name: searchedName }, page, perPage, controller.signal)
          return { products, count }
        } catch (error) {
          console.log(error)
        } finally {
          commit('setIsFetching', false)
        }
      }

      try {
        const controller = new AbortController()
        commit('setRequestController', controller)
        const { products, count } = await product.productsFromCollection(collectionIdOrSlug, { ableToSend }, page, perPage, controller.signal)
        return { products, count }
      } catch (error) {
        console.log(error)
      } finally {
        commit('setIsFetching', false)
      }
    },

    async fetchProducts({ state, commit }: { state: ProductState, commit: Commit }, { page = 0, searchedName, clientId, isNextPage, ableToSend, isForceRefresh }: { page: number, searchedName?: string, clientId?: string | undefined | null, isNextPage?: boolean, ableToSend?: boolean, isForceRefresh?: boolean }) {
      if (state.isFetchingProducts && page !== 0) return // allow new request if previous is not finished only is we ask for page 0
      
      // stop current request before starting a new one (usefull for fetching pages with a searchName param while user is typing)
      if (state.requestController) state.requestController.abort()
      
      commit('setIsFetching', true)
      clientId = clientId || null
      if (clientId !== state.currentClientId) isForceRefresh = true

      if (searchedName || ableToSend) {
        /**
         * DO NOT store data filtered nfts
         * we only want to store nfts by most recent receptionDate, not filtered nfts
         * (display order and pagination do not match)
         */
        // TODO: MAYBE save NFTS by id though ? too see later
        try {
          const controller = new AbortController()
          commit('setRequestController', controller)
          const { products, count } = await product.getProducts({ perPage, currentPage: page, searchedName, clientId, ableToSend, signal: controller.signal })
          return { products, count }
        } catch (error: any) {
          console.log(error)
          return
        } finally {
          commit('setIsFetching', false)
        }
      }

      const hasAlreadyTheRequestedPage = state.currentClientId === clientId && ((state.currentPage !== null && state.currentPage >= page && !!state.products.length) || state.total === state.products.length)

      if (!isForceRefresh && hasAlreadyTheRequestedPage) {
        // page already in store, force refresh display with current data
        commit('fetchProductsSuccess', { products: state.products, page: state.currentPage, total: state.total, isForceRefresh: true, clientId })
        commit('setIsFetching', false)
        // return all products if we request the first page
        if (!isNextPage) return { products: state.products, count: state.total }
        return {} // do not return nfts if we already returned the last page
      }

      try {
        const controller = new AbortController()
        commit('setRequestController', controller)
        const { products, count } = await product.getProducts({ perPage, currentPage: page, ableToSend, clientId, signal: controller.signal })
        commit('fetchProductsSuccess', { products, page, total: count, isForceRefresh, clientId })

        return { products, count }
      } catch (error: any) {
        console.log(error)
      } finally {
        commit('setIsFetching', false)
      }
    },

    async fetchNextProducts({ state, dispatch, commit }: { state: ProductState, dispatch: Dispatch, commit: Commit }, { page = 0, searchedName = '', clientId }: { page?: number, searchedName?: string, clientId?: string }) {
      let nextPage

      if (searchedName) {
        nextPage = page + 1
      } else {
        if (state.currentPage === null) return
        nextPage = state.currentPage + 1
      }

      return dispatch('fetchProducts', {
        page: nextPage,
        searchedName,
        isNextPage: true,
        clientId
      })
    },
    abortCurrentRequest({ state }: { state: ProductState }) {
      // stop current request before starting a new one (usefull for fetching pages with a searchName param while user is typing)
      if (state.requestController) state.requestController.abort()
    },
    reset({ dispatch, commit }: { dispatch: Dispatch, commit: Commit }) {
      // cancel request in progress if there is one before reset
      dispatch('abortCurrentRequest')
      commit('resetState')
    }
  }
}