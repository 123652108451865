import { isFingerprintAvailable } from "@/plugins/auth";
import { FCMRegister, FCMUnregister } from "@/plugins/fcm";
import { getContactsPermission } from "@/plugins/permissions";
import { isApplication } from "@/utils/platforms";
import { user } from "@ownesthq/owalt";
import { Commit } from "vuex";

interface PermissionState {
    isContactsAllowed: boolean | null;
    isBiometricsAllowed: boolean | null;
    isBiometricsAvailable: boolean; // does the phone have the biometrics capacity (face or finger IS registered in the phone)
    isNotificationsAllowed: boolean | null;
}
const storage = window.localStorage

export default {
    namespaced: true,

    state: (): PermissionState => ({
        isContactsAllowed: null,
        isBiometricsAllowed: null,
        isBiometricsAvailable: false, 
        isNotificationsAllowed: null,
    }),

    mutations: {
        // Contacts
        initContactsFromLocalStorage (state: PermissionState) {
            const contactValue = storage.getItem("isContactsAllowed")
            state.isContactsAllowed = contactValue === null ? null : contactValue === 'true'
        },
        saveContactsPermission (state: PermissionState, value: boolean) {
            state.isContactsAllowed = value
            storage.setItem("isContactsAllowed", value.toString());
        },
        // Biometrics
        initBiometricsFromLocalStorage (state: PermissionState) {
            // Biometrics
            const biometricValue = storage.getItem("isBiometricsAllowed")
            state.isBiometricsAllowed = biometricValue === null ? null : biometricValue === 'true'
        },
        saveBiometricsPermission (state: PermissionState, shouldUseBiometrics: boolean) {
            state.isBiometricsAllowed = shouldUseBiometrics
            storage.setItem("isBiometricsAllowed", shouldUseBiometrics.toString());
        },
        initNotificationsFromLocalStorage (state: PermissionState) {
            if (!isApplication()) return
            // Push Notifications
            const storageValue = storage.getItem('isNotificationsAllowed')
            state.isNotificationsAllowed = storageValue === null ? null : storageValue === 'true'
        },
        async enableNotifications (state: PermissionState) {
            if (!isApplication()) return
            state.isNotificationsAllowed = true
            // Save token in API
            const fcmToken = await FCMRegister();
            await user.addFCMToken(device.uuid, fcmToken)
            storage.setItem("isNotificationsAllowed", "true");
        },
        async disableNotifications (state: PermissionState) {
            if (!isApplication()) return
            if (state.isNotificationsAllowed) {
                state.isNotificationsAllowed = false
                FCMUnregister();
                await user.removeFCMToken(device.uuid)
                storage.setItem("isNotificationsAllowed", "false");
            }
        },
        async pauseNotifications (state: PermissionState) {
            // Stop push notifications when the user logs out.
            if (!isApplication()) return
            if (state.isNotificationsAllowed) {
                FCMUnregister();
                await user.removeFCMToken(device.uuid)
            }
        },
        async resumeNotifications (state: PermissionState) {
            if (!isApplication()) return
            if (state.isNotificationsAllowed) {
                const fcmToken = await FCMRegister();
                await user.addFCMToken(device.uuid, fcmToken)
            }
        },
        async saveBiometricsCapacity (state: PermissionState) {
            state.isBiometricsAvailable = await isFingerprintAvailable()
        }
    },
    actions: {
        async initNotifications ({ commit, state }: { commit: Commit, state: PermissionState }) {
            await commit('initNotificationsFromLocalStorage')
            if ([null, true].includes(state.isNotificationsAllowed)) {
                try {
                    if (state.isNotificationsAllowed === true) {
                        await commit('resumeNotifications')
                    }
                    await commit('enableNotifications')
                } catch (error){
                    await commit('disableNotifications')
                }
            }
        },
        async initPermissions({ commit }: { commit: Commit }) {
            commit('initBiometricsFromLocalStorage')
            commit('initContactsFromLocalStorage')
            await commit('saveBiometricsCapacity')
        }
    }
}