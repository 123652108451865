import store from '@/store'
import { createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "loggedLayout" */ '../layouts/LoggedLayout.vue'),
    children: [
      {
        path: '',
        name: 'home',
        component: () => import(/* webpackChunkName: "home" */ '../views/HomeView.vue'),
        meta: {
          menu: true,
          hasPullToRefresh: true
        },
      },
      {
        path: '/transactions',
        name: 'transactions',
        component: () => import(/* webpackChunkName: "transactions" */ '../views/TransactionsView.vue'),
        meta: {
          menu: true,
          hasPullToRefresh: true
        },
      },
      {
        path: '/transfer',
        name: 'transfer',
        redirect: '/transfer',
        meta: {
          menu: true
        },
      },
      {
        path: '/messaging',
        name: 'messaging',
        meta: {
          menu: true,
          hasPullToRefresh: true
        },
        component: () => import(/* webpackChunkName: "messaging" */ '../views/messages/MessagingView.vue'),
        children: [
          {
            path: '/messaging/:collectionId',
            name: 'messaging-collection',
            props: true,
            component: () => import(/* webpackChunkName: "messages" */ '../views/messages/MessagesView.vue'),
            meta: {
              menu: true,
              hideMenuOnMobile: true,
              hasPullToRefresh: false
            },
          },
        ]
      },
      {
        path: '/settings',
        name: 'settings',
        component: () => import(/* webpackChunkName: "settings" */ '../views/SettingsView.vue'),
        meta: {
          menu: true,
          hideMenuOnMobile: true,
        },
      },
      {
        path: '/collection/:_id',
        name: 'collection',
        props: true,
        component: () => import(/* webpackChunkName: "collections" */ '../views/collections/DetailsView.vue'),
        meta: {
          menu: true,
          hideMenuOnMobile: true,
          hasPullToRefresh: true
        },
      },
      {
        path: '/send/select-nft',
        name: 'nft-select',
        props: true,
        meta: {
          // requireUserVerification: true
          hasPullToRefresh: true
        },
        component: () => import(/* webpackChunkName: "select-nfts" */ '../views/nfts/SelectNft.vue'),
      },
      {
        path: '/nft/:_id',
        name: 'nft',
        props: true,
        meta: {
          public: true,
          menu: true,
          hideMenuOnMobile: true,
          hasPullToRefresh: true
        },
        component: () => import(/* webpackChunkName: "nfts" */ '../views/nfts/DetailsNftView.vue'),
      },
      {
        path: '/nft/:_id/gallery',
        name: 'nft-gallery',
        props: true,
        meta: {
          public: true,
          menu: true,
          hideMenuOnMobile: true,
        },
        component: () => import(/* webpackChunkName: "gallery" */ '../views/nfts/GalleryNftView.vue'),
      },
      {
        path: '/nft/send',
        name: 'nft-send',
        props: true,
        // meta: {
        //   requireUserVerification: true
        // },
        component: () => import(/* webpackChunkName: "send" */ '../views/nfts/SendView.vue'),
      },
      {
        path: '/nft/receive',
        name: 'nft-receive',
        props: true,
        component: () => import(/* webpackChunkName: "receive" */ '../views/nfts/ReceiveView.vue'),
      },
      {
        path: 'nft/:_id/repair-request/:repairId?',
        name: 'repairRequest',
        props: true,
        meta: {
          menu: true,
          hideMenuOnMobile: true,
        },
        component: () => import(/* webpackChunkName: "repairRequest" */ '../views/RepairRequestView.vue'),
      },
      {
        path: 'scan',
        name: 'scan-qrcode',
        props: true,
        component: () => import(/* webpackChunkName: "scan-qrcode" */ '../views/ScanQrCodeView.vue'),
      },
      {
        path: 'profile',
        name: 'profile',
        props: true,
        meta: {
          menu: true,
        },
        component: () => import(/* webpackChunkName: "profile" */ '../views/ProfileView.vue'),
      },
      {
        path: 'spaces',
        name: 'spaces',
        props: true,
        meta: {
          menu: true,
        },
        component: () => import(/* webpackChunkName: "spaces" */ '../views/SpacesView.vue'),
      },
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/login/LoginView.vue'),
    meta: {
      public: true
    }
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => import(/* webpackChunkName: "privacy-policy" */ '../views/PrivacyPolicyView.vue'),
    meta: {
      public: true
    }
  },
  {
    path: '/loginconfirmation',
    name: 'loginConfirmation',
    component: () => import(/* webpackChunkName: "loginConfirmation" */ '../views/login/loginConfirmationView.vue'),
    meta: {
      public: true
    }
  },
  {
    path: '/settings/email/confirmation',
    name: 'confirm-email',
    meta: {
      public: true
    },
    component: () => import(/* webpackChunkName: "confirm-email" */ '../views/ConfirmEmailView.vue'),
  }
]

const router = createRouter({
  history: !process.env.CORDOVA_PLATFORM && process.env.NODE_ENV === 'production' ? createWebHistory() : createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  },
})

// detect if we use the browser back button
let isHistoryGoBack = false
let currentHistoryPosition = 0

window.addEventListener('popstate', () => {
  isHistoryGoBack = window.history.state.position < currentHistoryPosition
})


router.beforeEach((to, _, next) => {
  // we need to wait a few ms to see if we detect a 'popstate' event
  // otherwise router.beforeEach is executed before the listener
  // TODO: find a better solution than a timeout
  setTimeout(() => {

    // DECOMMENT IF we want to cancel transition if we go back from footer link to another footer link
    // if (isItABackButton && (!to?.meta?.isMenuRoute || !from?.meta?.isMenuRoute))
    if (isHistoryGoBack) {
      store.dispatch('transition', { value: 'back' })
      isHistoryGoBack = false
    }
    // DECOMMENT IF we want to cancel transition if we go back from footer link to another footer link
    // else if (isItABackButton)
    //   store.dispatch('transition', { value: 'footer' })


    if (to?.meta?.public || to?.query?.token) return next()
    // const loggedIn = store.getters['auth/loggedIn']
    // if (!loggedIn && to.name !== 'nft') return next({ name: 'login' })

    // for routes protected by biometrics or SMS verification
    if (to?.meta?.requireUserVerification) {
      const isUserVerified = store.getters['users/isUserIdentityConfirmed']
      if (!isUserVerified) return
    }

    return next()
  }, 100)
})

router.afterEach((to) => {
  currentHistoryPosition = window.history.state.position

  // reset autorisation to access protected routes
  if (to?.meta?.requireUserVerification)
    store.commit('users/resetUserIdentityVerification')
})

export default router
