export default {
  "pages": {
    "title": {
      "my-collections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis colecciones"])},
      "activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actividades"])},
      "messaging": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensajes"])},
      "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfil"])},
      "spaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espacios"])},
      "gallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Galería"])}
    },
    "login": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La aplicación más simple para gestionar tus Coleccionables digitales ⭐️"])}
    }
  },
  "buttons": {
    "login": {
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Empezar!"])}
    }
  },
  "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceptar"])},
  "contact-permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Necesitamos tu permiso para acceder a tus contactos."])},
  "share-contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compartir tus contactos"])},
  "share-my-contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compartir mis contactos"])},
  "do-not-share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No compartir"])},
  "address-copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección copiada al portapapeles"])},
  "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
  "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiado"])},
  "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargar"])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sí"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "see-all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver todo"])},
  "no-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay datos para mostrar en esta sección."])},
  "enter-received-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingresa el código que recibiste en tu teléfono"])},
  "invalid-code-try-again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código inválido. Por favor, inténtalo de nuevo."])},
  "resend-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reenviar código"])},
  "retry-in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podrás volver a intentarlo en:"])},
  "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anterior"])},
  "received-by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recibido por"])},
  "sent-by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviado por"])},
  "recipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destinatario:"])},
  "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resumen"])},
  "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar"])},
  "fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gastos de transacción:"])},
  "biometric-authentication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autenticación biométrica"])},
  "confirm-identity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, confirma tu identidad"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicio"])},
  "collections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colecciones"])},
  "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajustes"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar"])},
  "no-collection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay colecciones para mostrar"])},
  "accept-by-clicking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al hacer clic en “Empezar” aceptas la"])},
  "privacy-policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["política de privacidad"])},
  "and-the": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["y los"])},
  "GCU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Términos y Condiciones"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción:"])},
  "collection": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colección:"])},
    "blockchain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blockchain"])},
    "supply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elementos"])},
    "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creador"])}
  },
  "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creado por:"])},
  "receivedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recibido en:"])},
  "tokenId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de serie:"])},
  "attributes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atributos"])},
  "date-unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha desconocida"])},
  "receive-nft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recibir un coleccionable digital"])},
  "show-qr-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar este código QR para recibir un coleccionable digital"])},
  "select-nft-to-send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Qué artículo(s) le gustaría enviar?"])},
  "no-nft-yet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aún no tienes Coleccionables digitales."])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siguiente"])},
  "is-sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Enviado!"])},
  "will-receive-soon": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Está enviado, el destinatario recibirá el NFT pronto"]), _normalize(["Está enviado, el destinatario recibirá los NFTs pronto"])])},
  "contact-permission-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Necesitamos su permiso para acceder a sus contactos. De esta forma, podrá enviar un Coleccionable digital a una persona de su agenda sin tener que escribir su número de teléfono manualmente."])},
  "send-nft-amount": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Enviar mi NFT a"]), _normalize(["Enviar mis NFT a"])])},
  "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactos"])},
  "blockchain-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección blockchain"])},
  "enter-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrese una dirección"])},
  "enter-phone-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrese un número de teléfono"])},
  "standard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estándar"])},
  "fast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rápido"])},
  "immediate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inmediato"])},
  "scan-qr-please": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, escanea el código QR."])},
  "no-recent-activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay actividad reciente por el momento."])},
  "sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviado"])},
  "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recibido"])},
  "updated-on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizado el:"])},
  "congrats-received-nft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Felicitaciones, has recibido un NFT! 🎉"])},
  "discover-nft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descubrir mi NFT"])},
  "authorize-biometrics-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para asegurar la seguridad de tu Owalt y conectarte más rápido, necesitamos que autorices la autenticación biométrica (huella dactilar o reconocimiento facial)."])},
  "authorize-biometrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorizar autenticación biométrica"])},
  "my-wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi monedero"])},
  "recently-received-nft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recibidos recientemente"])},
  "no-nft-received-recently": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No has recibido nada recientemente"])},
  "receive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recibir"])},
  "account-deletion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminación de cuenta"])},
  "account-deletion-are-you-sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Estás seguro de que deseas eliminar tu cuenta?"])},
  "thirty-days-before-account-deletion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tienes 30 días para recuperarlo antes de que se elimine permanentemente."])},
  "lost-nfts": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["NFT se perderá"]), _normalize(["NFTs se perderán"])])},
  "account-deletion-sure-about-nfts-lost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Estás realmente seguro de que deseas eliminar tu cuenta y perder los NFT en tu monedero?"])},
  "keep-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mantengo mi cuenta"])},
  "i-delete-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimino mi cuenta"])},
  "sorry-see-you-go": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lo sentimos, nos vemos."])},
  "account-soon-deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu cuenta será eliminada pronto"])},
  "account-deletion-thirty-days-left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tienes 30 días para recuperarla, después de lo cual se eliminará definitivamente."])},
  "errors": {
    "errorOccured": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se produjo un error, por favor inténtalo de nuevo"])},
    "oops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Oops...!"])}
  },
  "my-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi cuenta:"])},
  "safety": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seguridad:"])},
  "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soporte:"])},
  "terms-and-conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Términos y condiciones de uso"])},
  "privacy-policy-setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Política de privacidad"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar sesión"])},
  "contact-authorization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorización de contactos"])},
  "biometric-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos biométricos"])},
  "reconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reconectar"])},
  "do-not-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No permitir"])},
  "contact-permission-required-example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ejemplo: quieres enviar tu NFT a un amigo del que tienes el número de teléfono, pero no lo tienes memorizado. Simplemente selecciona su nombre en tu lista de contactos para poder enviarle el NFT."])},
  "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalles"])},
  "actions": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acciones"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar"])}
  },
  "add-memory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar una foto"])},
  "authenticity-certificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificado de autenticidad y garantía"])},
  "repair-request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitud de reparación"])},
  "my-pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis fotos"])},
  "block-nft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bloquear el NFT"])},
  "loss-or-theft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En caso de pérdida o robo"])},
  "make-opposition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presentar una oposición"])},
  "declare-nft-lost": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["¿Quieres declarar el NFT de tu ", _interpolate(_named("nftName")), " como perdido o robado? El NFT será bloqueado."])},
  "add-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar detalles"])},
  "sure-make-opposition": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["¿Estás seguro de que quieres bloquear el NFT de tu ", _interpolate(_named("nftName")), "?"])},
  "it-is-a-mistake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es un error"])},
  "yes-block-nft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sí, quiero bloquearlo"])},
  "withdraw-opposition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retirar mi oposición"])},
  "nft-will-be-unblocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El NFT será desbloqueado y se retirará el informe de pérdida / robo."])},
  "nft-blocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este NFT está bloqueado"])},
  "private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privado"])},
  "public": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Público"])},
  "no-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No has recibido ningún mensaje"])},
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargando..."])},
  "page-number": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["página"]), _normalize(["páginas"])])},
  "show-all-pages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar todas las páginas"])},
  "take-picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tomar una foto"])},
  "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validar"])},
  "give-memory-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Da un título a tu foto"])},
  "file-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Título del archivo"])},
  "add-file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar un archivo"])},
  "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoy"])},
  "week-of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semana del"])},
  "no-gallery-picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No has registrado ninguna foto para este NFT aún"])},
  "from-gallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desde mi galería"])},
  "email": {
    "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrese su correo electrónico"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asociar un correo electrónico a su cuenta de Owalt"])},
    "myAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi cuenta:"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo de confirmación"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Se ha enviado! Recibirás un correo de confirmación."])},
    "myEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi correo electrónico:"])},
    "errorConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha producido un error, no se ha podido validar su correo electrónico"])},
    "alreadyUsed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este correo electrónico ya está en uso"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico"])}
  },
  "enter-received-code-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrese el código recibido por correo electrónico"])},
  "confirmMyEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar"])},
  "warrantyCertificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificado de garantía"])},
  "manufacturerWarranty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garantía del fabricante: 2 años"])},
  "proofOfOwnership": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prueba de propiedad"])},
  "detainedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detenido por:"])},
  "ref": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ref:"])},
  "model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modelo:"])},
  "dateOfPurchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de compra:"])},
  "proofOfAuthenticity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prueba de autenticidad"])},
  "holderPassport-Halchimy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eres el titular del pasaporte"])},
  "blocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bloqueado"])},
  "exchange-with-client": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Intercambiar con ", _interpolate(_named("cliente"))])},
  "messaging": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensajería"])},
  "history": {
    "transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferencia de NFT"])},
    "opposition-Halchimy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bloqueo de pasaporte"])},
    "repair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitud de reparación"])},
    "addImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar una foto pública"])},
    "endRepair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reparación terminada"])},
    "button": {
      "seeRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver la solicitud"])},
      "seePhoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver la foto"])}
    },
    "withdrawOpposition-Halchimy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desbloqueo de pasaporte"])},
    "creation-Halchimy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creación de pasaporte"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historial: "])},
    "transfer-Halchimy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferencia de pasaporte"])},
    "creation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creación de NFT"])},
    "withdrawOpposition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desbloqueo de NFT"])},
    "opposition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bloqueo de NFT"])}
  },
  "product": {
    "details": {
      "buttons": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción"])},
        "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historial"])},
        "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acciones"])}
      },
      "label": {
        "tokenId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de serie"])},
        "tokenId-Halchimy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasaporte digital"])}
      }
    }
  },
  "wish-you-delete-picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Desea eliminar esta foto?"])},
  "are-you-sure-delete-picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta acción es irreversible. ¿Está seguro de que desea eliminar esta foto?"])},
  "picture-edition-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fotos"])},
  "private-picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privada"])},
  "public-picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pública"])},
  "picture-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de foto"])},
  "add-picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar una foto"])},
  "repair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reparación"])},
  "repairRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitud de reparación"])},
  "repairInProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reparación en curso"])},
  "repairFinished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reparación terminada"])},
  "searchCity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar una ciudad"])},
  "sendRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar la solicitud"])},
  "deposeMyProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposito mi producto"])},
  "finishedRepair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reparación terminada"])},
  "requestSend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitud enviada"])},
  "appointmentRepairCenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cita en el centro de reparación indicado"])},
  "repairCenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centro de reparación:"])},
  "addDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar una descripción del problema"])},
  "with-certified-repairer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Con un reparador certificado"])},
  "see-my-current-request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver mi solicitud actual:"])},
  "authenticity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autenticidad:"])},
  "qr-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código QR"])},
  "submitProductModal": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Depositar mi producto"])},
    "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Usted delega la responsabilidad a <strong>", _interpolate(_named("store")), "</strong>, deberá presentar la prueba de propiedad para recuperar su producto."])},
    "deposeMyProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Depositar mi producto"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No, todavía no estoy en la tienda"])}
  },
  "back-home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver al inicio"])},
  "mint-in-progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mint en curso..."])},
  "changeMyEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar mi correo electrónico"])},
  "is-confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Está confirmado"])},
  "mint-on-my-wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minted en mi monedero"])},
  "minted-on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minted en"])},
  "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propietario"])},
  "scan-product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escanear el código QR de un producto"])},
  "scan-qrcode-target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apunte el código QR para seleccionar el producto"])},
  "scan-qrcode-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El código QR no es reconocido por la aplicación."])},
  "addressBC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direcciones Blockchain"])},
  "contact-unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacto Desconocido"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicio de sesión:"])},
  "not-specified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No especificado"])},
  "notifications-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notificaciones Push"])},
  "add-to-contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar a contactos"])},
  "add-a-contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar un contacto"])},
  "give-contact-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrese el nombre para guardar el contacto"])},
  "contact-added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacto agregado"])},
  "contact-added-name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " se ha agregado a su libreta de direcciones"])},
  "forms": {
    "fields": {
      "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
      "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apellido"])}
    }
  },
  "serialNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de serie:"])},
  "externalWallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billetera externa"])},
  "contact-search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrese un nombre o un número de teléfono"])},
  "refuse-fingerprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No utilizar"])},
  "certified-ethereum-blockchain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificado en la blockchain Ethereum"])},
  "block-nft-Halchimy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bloquear el pasaporte"])},
  "declare-nft-lost-Halchimy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["¿Desea declarar el pasaporte de su ", _interpolate(_named("nftName")), " perdido o robado? El pasaporte se bloqueará."])},
  "sure-make-opposition-Halchimy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["¿Está seguro de que desea bloquear el pasaporte de su ", _interpolate(_named("nftName")), "?"])},
  "nft-blocked-Halchimy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este pasaporte está bloqueado"])},
  "no-gallery-picture-Halchimy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todavía no ha guardado ninguna foto para este pasaporte"])},
  "nft-will-be-unblocked-Halchimy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El pasaporte se desbloqueará y se retirará la denuncia de pérdida / robo."])},
  "discover-nft-Halchimy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descubrir mi pasaporte"])},
  "congrats-received-nft-Halchimy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Felicidades! Ha recibido un pasaporte digital 🎉"])},
  "send-nft-amount-Halchimy": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Enviar mi pasaporte"]), _normalize(["Enviar mis pasaportes"])])},
  "will-receive-soon-Halchimy": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Está enviado, el destinatario recibirá el pasaporte en breve"]), _normalize(["Está enviado, el destinatario recibirá los pasaportes en breve"])])},
  "tokenId-Halchimy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasaporte digital:"])},
  "holderPassport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eres el titular del NFT"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teléfono"])},
  "search-contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar contacto o número de teléfono"])},
  "public-pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fotos públicas"])},
  "cancel-repair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar la solicitud"])},
  "cancel-repair-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Desea eliminar esta solicitud de mantenimiento?"])},
  "cancel-repair-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta acción es irreversible."])},
  "accept-by-clicking-received-nft": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Al hacer clic en \"", _interpolate(_named("buttonText")), "\", aceptas los"])},
  "recaptcha": {
    "conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta aplicación está protegida por reCAPTCHA (<a href=\"https://policies.google.com/privacy\" target=\"_blank\">política de privacidad</a> - <a href=\"https://policies.google.com/terms\" target=\"_blank\">términos de servicio</a>)."])}
  },
  "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reintentar"])},
  "send-invalid-address-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se produjo un error al enviar. Es posible que el número de teléfono, la dirección o el nombre ENS del destinatario no existan."])},
  "pending-transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En proceso de transferencia"])},
  "performed-service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicio realizado"])},
  "service-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de servicio"])},
  "send-invalid-address-typing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección inválida"])},
  "weeks-ago": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Esta semana"]), _normalize(["La semana pasada"]), _normalize(["Hace ", _interpolate(_named("count")), " semanas"])])},
  "mint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mint"])},
  "collectibles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis coleccionables"])},
  "no-nft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay NFT para mostrar"])},
  "new-messages": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " Nuevo mensaje"]), _normalize([_interpolate(_named("count")), " Nuevos mensajes"])])},
  "concerned-nfts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NFT afectados"])},
  "today-at": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hoy a las ", _interpolate(_named("time"))])},
  "nfts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nfts"])},
  "profile": {
    "my-badges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis insignias"])}
  },
  "no-badges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay Badges para mostrar"])},
  "exchanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vientos alisios"])},
  "new-user-alias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuevo pseudo"])},
  "spaces": {
    "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elija su espacio"])},
    "selectmain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceda a su wallet"])}
  },
  "congrats-received-nft-homeety": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Felicidades! Ha recibido un NFT 🎉"])},
  "discover-nft-homeety": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descubrir mi NFT"])},
  "send-nft-amount-homeety": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Enviar mis NFT"]), _normalize(["Enviar mis NFTs"])])},
  "will-receive-soon-homeety": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Está enviado, el destinatario recibirá el NFT en breve"]), _normalize(["Está enviado, el destinatario recibirá los NFTs en breve"])])},
  "block-nft-homeety": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bloquear el NFT"])},
  "load-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver más"])},
  "update-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es hora de actualizar la aplicación!"])},
  "update-available-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Una nueva versión de nuestra aplicación está disponible en la tienda."])},
  "GCU-update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuestras condiciones generales cambian"])},
  "GCU-update-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para poder seguir accediendo a tu cuenta Owalt, por favor, acéptalas."])},
  "read-GCU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lea las condiciones generales"])},
  "privacy-policy-update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuestra política de privacidad evoluciona"])},
  "privacy-policy-update-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para continuar accediendo a tu cuenta Owalt, por favor acepta este acuerdo."])},
  "read-privacy-policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lea la política de privacidad"])},
  "actuality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noticias"])},
  "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["o"])},
  "show-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aprende màs"])},
  "resend-code-whatsap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "enter-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrese un correo electrónico"])},
  "owalt": {
    "confirm": {
      "delete": {
        "communication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
      }
    }
  },
  "downloadAndInstall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["descargar e instalar"])}
}