import store from '@/store'
import i18n from '../i18n'
const { t } = i18n.global

export const isFingerprintAvailable = (): Promise<boolean> => {
    return new Promise((resolve, reject) => {
        Fingerprint.isAvailable(
            (result: string) => {
                resolve(['biometric', 'face', 'finger'].includes(result));
            },
            (error: any) => {
                console.log(error)
                resolve(false);
            },
            {}
        )
    })
}

export const showFingerprint = ({ title, cancelButton, description, isPermissionChange }: { title?: string, cancelButton?: string, description?: string, isPermissionChange?: boolean } = {}) => {
    return new Promise((resolve, reject) => {
        const state: any = store.state
        const isBiometricsAllowed = state.permissions.isBiometricsAllowed

        if (!isBiometricsAllowed && !isPermissionChange) {
            reject({ message: 'Not allowed to use biometric identification' })
            return
        }

        Fingerprint.show({
            title: title || t('biometric-authentication'),
            description: description || t('confirm-identity'),
            fallbackButtonTitle: cancelButton || t('cancel'),
            cancelButtonTitle: cancelButton || t('cancel'),
            disableBackup: true,
            confirmationRequired: true
        }, (result: any) => {
            resolve(result)
        }, (error: any) => {
            reject(error)
        });
    })
}