import { parsePhoneNumber } from 'libphonenumber-js';
import { user, legal } from '@ownesthq/owalt';
import { Commit } from 'vuex';

interface State {
  phone: string|null;
  email: string|null;
  icon: string|null;
  alias: string|null;
  collectionsCount: number;
  productsCount: number;
  transactionsCount: number;
  isEmailConfirmed: boolean;
  wallet: {
    address: string|null;
    _id: string|null;
  };
  _id: string|null;
  hasPhoneBeenTemporarilyVerified: boolean;
  // legals
  areLatestPrivacyPolicyAccepted: boolean|null;
  areLatestTermsAndConditionsAccepted: boolean|null;
}

export default {
  namespaced: true,

  state: (): State => ({
    phone: null,
    email: null,
    icon: null,
    alias: null,
    collectionsCount: 0,
    productsCount: 0,
    transactionsCount: 0,
    isEmailConfirmed: false,
    wallet: {
      address: null,
      _id: null
    },
    _id: null,
    areLatestPrivacyPolicyAccepted: null,
    areLatestTermsAndConditionsAccepted: null,
    hasPhoneBeenTemporarilyVerified: false
  }),

  getters: {
    walletAddress: (state: State) => state.wallet.address,
    isUserIdentityConfirmed (state: State) {
      return state.hasPhoneBeenTemporarilyVerified
    },
    userCountryCode: (state: State) => {
      if (!state.phone) return null
      const phoneNumber = parsePhoneNumber(state.phone)
      return phoneNumber.country
    }
  },

  mutations: {
    fetchSuccess(state: State, { phone, email, isEmailConfirmed, wallet, icon, alias, collectionsCount, productsCount, transactionsCount, _id } : { phone: string, email: string, isEmailConfirmed: boolean, wallet: any, icon: string, alias: string, collectionsCount: number, productsCount: number, transactionsCount: number, _id: string }) {
      state.phone = phone
      state.email = email
      state.isEmailConfirmed = isEmailConfirmed
      state.wallet = wallet
      state.icon = icon
      state.alias = alias
      state.collectionsCount = collectionsCount
      state.productsCount = productsCount
      state.transactionsCount = transactionsCount
      state._id = _id
    },
    addEmail(state: State, { email } : { email: string }) {
      state.email = email
    },
    confirmEmail(state: State) {
      state.isEmailConfirmed = true
    },
    reset(state: State) {
      state.phone = null
      state.email = null
      state.icon = null
      state.alias = null
      state.collectionsCount = 0
      state.productsCount = 0
      state.transactionsCount = 0
      state.isEmailConfirmed = false
      state.wallet = {
        address: null,
        _id: null
      }
      state._id = null
    },
    verifyUserIdentify(state: State) {
      state.hasPhoneBeenTemporarilyVerified = true
    },
    resetUserIdentityVerification(state: State) {
      state.hasPhoneBeenTemporarilyVerified = false
    },
    saveLegalsAcceptation(state: State, legalsAcceptation: any) {
      state.areLatestPrivacyPolicyAccepted = legalsAcceptation.privacyPolicy
      state.areLatestTermsAndConditionsAccepted = legalsAcceptation.termsAndConditions
    }
  },

  actions: {
    async fetch({ commit, state}: any) {
      if (state.phone) return 
      const { data } = await user.findOne('me')
      commit('fetchSuccess', data)
    },
    async getLastLegalsAcceptation({ commit }: { commit: Commit }) {
      const lastLegalsAcceptedRes = await legal.getLastLegalsAccepted();
      commit('saveLegalsAcceptation', lastLegalsAcceptedRes.data)
    },

    async setIcon ({ commit, state }: { commit: Commit, state: State }, { extension, image } : { extension: string, image: Blob }) {
      try {
        const { url } = await user.setIcon({ extension, image })
        commit('fetchSuccess', { ...state, icon: url })
      } catch (e) {
        console.error(e)
      }
    },

    async setAlias({ commit, state }: any, { alias }: { alias: string }) {
      try {
        await user.setAlias(alias)
        commit('fetchSuccess', { ...state, alias })
      } catch (e) {
        console.error(e)
      }
    },
  }
}