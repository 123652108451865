export default {
  "pages": {
    "title": {
      "my-collections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes collections"])},
      "activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activité"])},
      "messaging": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messagerie"])},
      "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
      "spaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spaces"])},
      "gallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gallerie"])}
    },
    "login": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’application la plus simple pour gérer vos Digital Collectibles ⭐️"])}
    }
  },
  "buttons": {
    "login": {
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["C'est parti !"])}
    }
  },
  "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepter"])},
  "contact-permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous avons besoin de votre permission pour accéder à vos contacts."])},
  "share-contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partager vos contacts"])},
  "share-my-contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partager mes contacts"])},
  "do-not-share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne pas partager"])},
  "address-copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse copiée dans le presse-papier"])},
  "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])},
  "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copié"])},
  "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger"])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non"])},
  "see-all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir tout"])},
  "no-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de données à afficher pour cette section."])},
  "enter-received-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez le code reçu sur votre téléphone"])},
  "invalid-code-try-again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code invalide. Veuillez réessayer."])},
  "resend-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renvoyer le code"])},
  "retry-in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pourrez réessayer dans :"])},
  "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Précédent"])},
  "received-by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyé à"])},
  "sent-by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reçu de"])},
  "recipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destinataire :"])},
  "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récapitulatif"])},
  "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer"])},
  "fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frais de transaction :"])},
  "biometric-authentication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentification biométrique"])},
  "confirm-identity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez confirmer votre identité"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accueil"])},
  "collections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collections"])},
  "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche"])},
  "no-collection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de collections à afficher"])},
  "accept-by-clicking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cliquant sur “C’est parti” vous acceptez la"])},
  "privacy-policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["politique de confidentialité"])},
  "and-the": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["et les"])},
  "GCU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CGU"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description :"])},
  "collection": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collection :"])},
    "blockchain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blockchain"])},
    "supply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éléments"])},
    "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créateur"])}
  },
  "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créé par :"])},
  "receivedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reçu le :"])},
  "tokenId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de série :"])},
  "attributes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attributs"])},
  "date-unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date inconnue"])},
  "receive-nft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recevoir un Digital Collectible"])},
  "show-qr-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montrer ce QR code pour recevoir un collectible"])},
  "select-nft-to-send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quel(s) collectible(s) souhaitez-vous envoyer?"])},
  "no-nft-yet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas encore de collectibles."])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivant"])},
  "is-sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["C'est envoyé !"])},
  "will-receive-soon": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["C’est envoyé, le destinataire va recevoir le collectible d’ici peu"]), _normalize(["C’est envoyé, le destinataire va recevoir les collectibles d’ici peu"])])},
  "contact-permission-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous avons besoin de votre permission pour accéder à vos contacts. Vous pourrez alors envoyer un collectible à une personne de votre carnet d'adresses sans avoir à saisir son numéro de téléphone manuellement."])},
  "send-nft-amount": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Envoyer mon collectible à"]), _normalize(["Envoyer mes collectibles à"])])},
  "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts"])},
  "blockchain-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse blockchain"])},
  "enter-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez une adresse blockchain"])},
  "enter-phone-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrer un numéro de téléphone"])},
  "standard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard"])},
  "fast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapide"])},
  "immediate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instantané"])},
  "scan-qr-please": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez scanner le QRCode."])},
  "no-recent-activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas d'activité récente pour le moment."])},
  "sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyé"])},
  "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reçu"])},
  "updated-on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis à jour le :"])},
  "congrats-received-nft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Félicitation vous avez reçu un NFT ! 🎉"])},
  "discover-nft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Découvrir mon NFT"])},
  "authorize-biometrics-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour assurer la sécurité de votre Owalt et vous connecter plus rapidement, nous avons besoin que vous autorisiez l'authentification biométrique (empreinte digitale ou reconnaissance faciale)."])},
  "authorize-biometrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoriser l'authentification biométrique"])},
  "my-wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon Wallet"])},
  "recently-received-nft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reçus récemment"])},
  "no-nft-received-recently": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez rien reçu récemment"])},
  "receive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recevoir"])},
  "account-deletion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suppression du compte"])},
  "account-deletion-are-you-sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir supprimer votre compte ?"])},
  "thirty-days-before-account-deletion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous disposez de 30 jours pour le récupérer avant sa suppression définitive."])},
  "lost-nfts": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Collectible sera perdu"]), _normalize(["Collectibles seront perdus"])])},
  "account-deletion-sure-about-nfts-lost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous vraiment sûr de vouloir supprimer votre compte et perdre tout les objets numérique présents dans votre portefeuille ?"])},
  "keep-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je garde mon compte"])},
  "i-delete-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je supprime mon compte"])},
  "sorry-see-you-go": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navrés de vous voir partir"])},
  "account-soon-deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre compte sera supprimé prochainement"])},
  "account-deletion-thirty-days-left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous disposez de 30 jours pour le récupérer, après quoi il sera définitivement supprimé."])},
  "errors": {
    "errorOccured": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue, veuillez réessayer"])},
    "oops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oups... !"])}
  },
  "my-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon compte :"])},
  "safety": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sécurité :"])},
  "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support :"])},
  "terms-and-conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conditions générales d'utilisation"])},
  "privacy-policy-setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politique de confidentialité"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déconnexion"])},
  "contact-authorization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorisation contacts"])},
  "biometric-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Données biométriques"])},
  "reconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se reconnecter"])},
  "do-not-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne pas autoriser"])},
  "contact-permission-required-example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exemple : vous souhaitez envoyer votre collectible à un ami dont vous avez le numéro de téléphone, mais ne le connaissez pas par cœur ? Il vous suffit de sélectionner son nom dans la liste de vos contacts afin de pourvoir lui envoyer."])},
  "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails"])},
  "actions": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter"])}
  },
  "add-memory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une photo"])},
  "authenticity-certificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificat d'authenticité et de garantie"])},
  "repair-request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demande de réparation"])},
  "my-pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes photos"])},
  "block-nft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bloquer le NFT"])},
  "loss-or-theft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cas de perte ou de vol"])},
  "make-opposition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faire opposition"])},
  "declare-nft-lost": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Voulez-vous déclarer le NFT de votre ", _interpolate(_named("nftName")), " perdu ou volé ? Le NFT sera bloqué."])},
  "add-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter des détails"])},
  "sure-make-opposition": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Êtes-vous vraiment sûr de vouloir bloquer le NFT de votre ", _interpolate(_named("nftName")), " ?"])},
  "it-is-a-mistake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["C'est une erreur"])},
  "yes-block-nft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui, je veux le bloquer"])},
  "withdraw-opposition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retirer mon opposition"])},
  "nft-will-be-unblocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le NFT sera débloqué et le signalement de perte / vol retiré."])},
  "nft-blocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce NFT est bloqué"])},
  "private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privé"])},
  "public": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publique"])},
  "no-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas reçu de message"])},
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chargement..."])},
  "page-number": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["page"]), _normalize([" pages"])])},
  "show-all-pages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher toutes les pages"])},
  "take-picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prendre une photo"])},
  "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valider"])},
  "give-memory-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donnez un titre à votre photo"])},
  "file-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titre du fichier"])},
  "add-file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un fichier"])},
  "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aujourd'hui"])},
  "week-of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semaine du"])},
  "no-gallery-picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas encore enregistré de photo pour ce NFT"])},
  "from-gallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Depuis ma galerie"])},
  "email": {
    "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez votre mail"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Associer un email à votre compte Owalt"])},
    "myAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon compte :"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mail de confirmation"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["C’est envoyé, vous allez recevoir un mail de confirmation."])},
    "myEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon Email :"])},
    "errorConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue, votre email n'a pas pu être validé"])},
    "alreadyUsed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce mail est déjà utilisé "])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])}
  },
  "enter-received-code-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrer le code reçu par mail"])},
  "confirmMyEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer"])},
  "warrantyCertificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificat de garantie"])},
  "manufacturerWarranty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garantie constructeur: 2 ans"])},
  "proofOfOwnership": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preuve de propriété"])},
  "detainedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détenu par :"])},
  "ref": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réf :"])},
  "model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modèle :"])},
  "dateOfPurchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d'achat :"])},
  "proofOfAuthenticity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preuve d'authenticité"])},
  "holderPassport-Halchimy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes le titulaire du passeport"])},
  "blocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bloqué"])},
  "exchange-with-client": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Échanger avec ", _interpolate(_named("client"))])},
  "messaging": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messagerie"])},
  "history": {
    "transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfert NFT"])},
    "opposition-Halchimy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blocage du passeport"])},
    "repair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demande d'entretien"])},
    "addImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajout d'une photo publique"])},
    "endRepair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entretien terminé"])},
    "button": {
      "seeRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir la demande"])},
      "seePhoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir la photo"])}
    },
    "withdrawOpposition-Halchimy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déblocage du passeport"])},
    "creation-Halchimy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Création du passeport"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historique : "])},
    "transfer-Halchimy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfert passeport"])},
    "creation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Création du NFT"])},
    "withdrawOpposition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déblocage du NFT"])},
    "opposition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blocage du NFT"])}
  },
  "product": {
    "details": {
      "buttons": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
        "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historique"])},
        "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])}
      },
      "label": {
        "tokenId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de série"])},
        "tokenId-Halchimy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passeport numérique"])}
      }
    }
  },
  "wish-you-delete-picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Souhaitez-vous supprimer cette photo ?"])},
  "are-you-sure-delete-picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette action est irréversible. Êtes-vous sûr de vouloir supprimer cette photo ?"])},
  "picture-edition-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photos"])},
  "private-picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privée"])},
  "public-picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publique"])},
  "picture-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de photo"])},
  "add-picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une photo"])},
  "repair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réparation"])},
  "repairRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demande d’entretien"])},
  "repairInProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entretien en cours"])},
  "repairFinished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entretien terminé"])},
  "searchCity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher une ville"])},
  "sendRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer la demande"])},
  "deposeMyProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je dépose mon produit"])},
  "finishedRepair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réparation terminée"])},
  "requestSend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demande envoyée"])},
  "appointmentRepairCenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendez vous dans le centre de réparation indiqué"])},
  "repairCenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centre de réparation :"])},
  "addDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajoutez une description du problème"])},
  "with-certified-repairer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avec un réparateur certifié"])},
  "see-my-current-request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir ma demande en cours :"])},
  "authenticity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authenticité :"])},
  "qr-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR Code"])},
  "submitProductModal": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déposer mon produit"])},
    "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous déléguez la responsabilité a <strong>", _interpolate(_named("store")), "</strong>, vous devrez présenter la preuve de propriété pour récupérer votre produit."])},
    "deposeMyProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je dépose mon produit"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non, je ne suis pas encore au centre"])}
  },
  "back-home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour à l'accueil"])},
  "mint-in-progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mint en cours..."])},
  "changeMyEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer mon mail"])},
  "is-confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["C'est validé"])},
  "mint-on-my-wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minté sur mon wallet"])},
  "minted-on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minté sur"])},
  "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propriétaire"])},
  "scan-product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scaner le qr code d’un produit"])},
  "scan-qrcode-target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visez le QR code pour sélectionner le produit"])},
  "scan-qrcode-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le QR code n’est pas reconnu par l’application."])},
  "addressBC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresses Blockchain"])},
  "contact-unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Inconnu"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion :"])},
  "not-specified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non renseigné"])},
  "notifications-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Push Notifications"])},
  "add-to-contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter en contact"])},
  "add-a-contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un contact"])},
  "give-contact-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez renseigner le nom sous lequel enregistrer le contact"])},
  "contact-added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact ajouté"])},
  "contact-added-name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " a été ajouté à votre carnet d'adresses"])},
  "forms": {
    "fields": {
      "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
      "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])}
    }
  },
  "serialNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de série :"])},
  "externalWallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallet externe"])},
  "contact-search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez un nom, un numéro de téléphone"])},
  "refuse-fingerprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne pas utiliser"])},
  "certified-ethereum-blockchain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certifié sur la blockchain Ethereum"])},
  "block-nft-Halchimy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bloquer le passeport"])},
  "declare-nft-lost-Halchimy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Voulez-vous déclarer le passeport de votre ", _interpolate(_named("nftName")), " perdu ou volé ? Le passeport sera bloqué."])},
  "sure-make-opposition-Halchimy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Êtes-vous vraiment sûr de vouloir bloquer le passeport de votre ", _interpolate(_named("nftName")), " ?"])},
  "nft-blocked-Halchimy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce passeport est bloqué"])},
  "no-gallery-picture-Halchimy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas encore enregistré de photo pour ce passeport"])},
  "nft-will-be-unblocked-Halchimy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le passeport sera débloqué et le signalement de perte / vol retiré."])},
  "discover-nft-Halchimy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Découvrir mon passeport"])},
  "congrats-received-nft-Halchimy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Félicitation vous avez reçu un passeport numérique ! 🎉"])},
  "send-nft-amount-Halchimy": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Envoyer mon passeport"]), _normalize(["Envoyer mes passeports "])])},
  "will-receive-soon-Halchimy": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["C’est envoyé, le destinataire va recevoir le passeport d’ici peu"]), _normalize(["C’est envoyé, le destinataire va recevoir les passeports d’ici peu"])])},
  "tokenId-Halchimy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passeport numérique :"])},
  "holderPassport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes le titulaire du NFT"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone"])},
  "search-contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher un contact ou un numéro de téléphone"])},
  "public-pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photos publiques"])},
  "cancel-repair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler la demande"])},
  "cancel-repair-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Souhaitez-vous supprimer cette demande d’entretien ?"])},
  "cancel-repair-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette action est irréversible."])},
  "accept-by-clicking-received-nft": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["En cliquant sur \"", _interpolate(_named("buttonText")), "\" vous acceptez la"])},
  "recaptcha": {
    "conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette application est protégée par reCAPTCHA (<a href=\"https://policies.google.com/privacy\" target=\"_blank\">politique de confidentialité</a> - <a href=\"https://policies.google.com/terms\" target=\"_blank\">conditions d'utilisations</a>)."])}
  },
  "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réessayer"])},
  "send-invalid-address-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue lors de l'envoi. Il se pourrait que le numéro de téléphone, l'adresse ou le nom ENS du destinataire n'existe pas."])},
  "pending-transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En transfert"])},
  "performed-service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service réalisé"])},
  "service-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de service"])},
  "send-invalid-address-typing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse invalide"])},
  "weeks-ago": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Cette semaine"]), _normalize(["La semaine dernière"]), _normalize(["Il y a ", _interpolate(_named("count")), " semaines"])])},
  "mint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mint"])},
  "collectibles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes collectibles"])},
  "no-nft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de NFTs à afficher"])},
  "new-messages": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " Nouveau message"]), _normalize([_interpolate(_named("count")), " Nouveaux messages"])])},
  "concerned-nfts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NFTs concernés"])},
  "today-at": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Aujourd'hui à ", _interpolate(_named("time"))])},
  "nfts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nfts"])},
  "profile": {
    "my-badges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes badges"])}
  },
  "no-badges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de Badges à afficher"])},
  "exchanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échanges"])},
  "new-user-alias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau pseudo"])},
  "spaces": {
    "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ou choisissez un Space"])},
    "selectmain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accédez à votre wallet"])}
  },
  "congrats-received-nft-homeety": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Félicitation vous avez reçu un NFT ! 🎉"])},
  "discover-nft-homeety": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Découvrir mon NFT"])},
  "send-nft-amount-homeety": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Envoyer mon NFT"]), _normalize(["Envoyer mes NFTs"])])},
  "will-receive-soon-homeety": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["C’est envoyé, le destinataire va recevoir le NFT d’ici peu"]), _normalize(["C’est envoyé, le destinataire va recevoir les NFTs d’ici peu"])])},
  "block-nft-homeety": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bloquer le NFT"])},
  "load-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir plus"])},
  "update-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il est temps de mettre l'application à jour!"])},
  "update-available-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une nouvelle version de notre application est disponible dans le store."])},
  "GCU-update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nos CGU évoluent"])},
  "GCU-update-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour pouvoir continuer à accéder à votre compte Owalt, merci de les accepter."])},
  "read-GCU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lire les CGU"])},
  "privacy-policy-update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notre politique de confidentialité évolue"])},
  "privacy-policy-update-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour pouvoir continuer à accéder à votre compte Owalt, merci de l'accepter."])},
  "read-privacy-policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lire la politique de confidentialité"])},
  "actuality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualités"])},
  "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ou"])},
  "show-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Découvrir plus"])},
  "resend-code-whatsap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renvoyer le code avec Whatsapp"])},
  "enter-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrer un email"])},
  "owalt": {
    "confirm": {
      "delete": {
        "communication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir supprime cette communication ?"])}
      }
    }
  },
  "downloadAndInstall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger et installer"])}
}