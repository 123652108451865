import {Message, MessageProduct} from "@/types/messages";

export class MessageDto  {

  message: Message
  nftCollection: any;
  
  constructor({
                _id,
                fromClient,
                messageBody,
                publishedAt,
                nftCollection,
                seenAt,
                products
              }: {
    _id: string
    fromClient: any
    messageBody: {
      files: string[]
      images: any[]
      isEncrypted: boolean
      text: string
      title: string
      publishedAt: string
      url: string
    },
    publishedAt: string,
    nftCollection: {
      icon: any
      name: string
    },
    seenAt?: string,
    products: any[]
  }) {
    this.message = {
      _id: _id,
      text: messageBody.text,
      isEncrypted: messageBody.isEncrypted,
      seenAt: seenAt || '',
      isSeen: seenAt !== undefined ? true : false,
      createdAt: publishedAt || messageBody.publishedAt,
      publishedAt: publishedAt || messageBody.publishedAt,
      nftCollection:  nftCollection,
      files: messageBody.files.map(file => {
        return {
          url: file,
          extension: file.split('.').pop() || ''
        }
      }),
      image: messageBody.images && messageBody.images.length > 0 ? { url:messageBody.images[0], _id: messageBody.images[0]} : undefined,
      url: messageBody.url,
      title: messageBody.title,
      products: products,
    }
    this.nftCollection =  nftCollection
  }


}