import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoginModal = _resolveComponent("LoginModal")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_loading = _resolveComponent("loading")!
  const _component_LegalsModal = _resolveComponent("LegalsModal")!
  const _component_UpdateVersionModal = _resolveComponent("UpdateVersionModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isLoginModalShown)
      ? (_openBlock(), _createBlock(_component_LoginModal, {
          key: 0,
          "show-modal": true,
          onLogin: _ctx.hideLoginModal,
          "onShow:privacyPolicy": _ctx.handleOpenPrivacyPolicyModal,
          "onShow:termsAndConditions": _ctx.handleOpenTermsAndConditionsModal
        }, null, 8, ["onLogin", "onShow:privacyPolicy", "onShow:termsAndConditions"]))
      : (!_ctx.waitFingerprint)
        ? (_openBlock(), _createBlock(_component_router_view, { key: 1 }))
        : _createCommentVNode("", true),
    _createVNode(_component_loading, {
      active: _ctx.isLoading,
      "lock-scroll": true,
      loader: "spinner"
    }, null, 8, ["active"]),
    (_ctx.isTermsAndConditionsModalShown)
      ? (_openBlock(), _createBlock(_component_LegalsModal, {
          key: 2,
          "legal-text": _ctx.termsAndConditionsText,
          "show-modal": _ctx.isTermsAndConditionsModalShown,
          onClose: _ctx.handleCloseTermsAndConditionsModal
        }, null, 8, ["legal-text", "show-modal", "onClose"]))
      : _createCommentVNode("", true),
    (_ctx.isPrivacyPolicyModalShown)
      ? (_openBlock(), _createBlock(_component_LegalsModal, {
          key: 3,
          "legal-text": _ctx.privacyPolicyText,
          "show-modal": _ctx.isPrivacyPolicyModalShown,
          onClose: _ctx.handleClosePrivacyPolicyModal
        }, null, 8, ["legal-text", "show-modal", "onClose"]))
      : _createCommentVNode("", true),
    (_ctx.isUpdateVersionModalShown)
      ? (_openBlock(), _createBlock(_component_UpdateVersionModal, {
          key: 4,
          showModal: _ctx.isUpdateVersionModalShown,
          title: _ctx.$t('update-available'),
          description: _ctx.$t('update-available-description'),
          onClose: _ctx.handleCloseUpdateVersionModal,
          onUpdate: _ctx.handleUpdateUpdateVersionModal,
          forceUpdate: _ctx.isForceUpdateModal
        }, null, 8, ["showModal", "title", "description", "onClose", "onUpdate", "forceUpdate"]))
      : _createCommentVNode("", true)
  ], 64))
}