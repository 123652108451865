import { transaction } from '@ownesthq/owalt';

interface TransactionState {
  list: any[]
  total: number | null
  currentPage: number | null
  lastFetch: Date | null
  isFetching: boolean
  currentClientId: null | string
  requestController: null | AbortController // use to cancel a request in progress
}

const perPage = 20

const initialState = {
  list: [],
  total: null,
  currentPage: null,
  lastFetch: null,
  isFetching: false,
  currentClientId: null,
  requestController: null
}

export default {
  namespaced: true,
  state: (): TransactionState => (JSON.parse(JSON.stringify(initialState))),

  mutations: {
    saveTransactions(state: TransactionState, { transactions, total, page, isForceRefresh, clientId }: { transactions: Array<any>, total: number, page: number, isForceRefresh?: boolean, clientId?: string }) {
      if (isForceRefresh)
        state.list = []

      state.list.push(...transactions);
      state.total = total;
      state.currentPage = page;
      state.currentClientId = clientId || null
    },
    setLastFetch(state: TransactionState) {
      state.lastFetch = new Date(Date.now())
    },
    reset(state: TransactionState) {
      state.list = []
      state.total = null
      state.currentPage = null
      state.lastFetch = null
      state.isFetching= false
      state.currentClientId = null
      state.requestController = null
    },
    setIsFetching(state: TransactionState, bool: boolean) {
      state.isFetching = bool
    },
    setRequestController(state: TransactionState, controller: AbortController) {
      state.requestController = controller
    },
  },

  actions: {
    async getTransactions({ commit, state }: { commit: any, state: TransactionState }, { page, forceRefresh, isNextPageRequest, clientId }: { page: number, forceRefresh?: boolean, isNextPageRequest?: boolean, clientId?: string | null }) {
      if (state.isFetching && page !== 0) return
      
      // stop current request before starting a new one (usefull for fetching pages with a searchName param while user is typing)
      if (state.requestController) state.requestController.abort()

      commit('setIsFetching', true)
      clientId = clientId || null
      if (clientId !== state.currentClientId) forceRefresh = true

      const hasAlreadyTheRequestedPage = state.currentClientId === clientId && ((state.currentPage !== null && state.currentPage >= page && !!state.list.length) || state.total === state.list.length)
      if (!forceRefresh && hasAlreadyTheRequestedPage) {
        // Requested page is already in the store || all transactions are saved        
        commit('saveTransactions', {
          transactions: [...state.list],
          total: state.total,
          page: state.currentPage,
          isForceRefresh: true,
          clientId
        })
        commit('setIsFetching', false)

        if (isNextPageRequest) return []
        return state.list
      }

      const controller = new AbortController()
      commit('setRequestController', controller)
      const data = await transaction.getTransactions({ filter: 'all', perPage, currentPage: page, clientId, signal: controller.signal })

      commit('setLastFetch')
      commit('saveTransactions', {
        transactions: data.transactions,
        total: data.count,
        page,
        isForceRefresh: forceRefresh,
        clientId
      })
      commit('setIsFetching', false)
      return data.transactions
    },

    async fetchNextTransactions({ dispatch, state }: any, {clientId}: {clientId?: string}) {
      const nextPage = state.currentPage + 1
      return dispatch('getTransactions', {
        page: nextPage,
        isNextPageRequest: true,
        clientId
      })
    }
  }
}