import { auth } from '@ownesthq/owalt'
import { AxiosResponse } from 'axios'
import { Commit } from 'vuex'

let refreshCall: Promise<any> | null = null
interface AuthState { 
    accessToken: string|null
    refreshToken: string|null
    isUserSecurityActivated: boolean
    phone: string|null
    email: string|null
    isUserRefreshing: boolean
}

const authState: AuthState = {
    accessToken: null,
    refreshToken: null,
    isUserSecurityActivated: true, // allow / disallow biometric check on app brought to foreground
    phone: null,
    email: null,
    isUserRefreshing: false
}

export default {
    namespaced: true,

    state: () => ( authState ),

    getters: {
        loggedIn: (state: AuthState) => { return !!state.accessToken }
    },

    mutations: {
        initFromLocalStorage (state: AuthState) {
            const refreshToken = window.localStorage.getItem('refreshToken')
            const accessToken = window.localStorage.getItem('accessToken')
            auth.setRefreshToken(refreshToken)
            auth.setAccessToken(accessToken)
            state.accessToken = accessToken
            state.refreshToken = refreshToken
        },
        saveTokens(state: AuthState, { accessToken, refreshToken }: any = {}) {
            auth.setRefreshToken(refreshToken || null)
            auth.setAccessToken(accessToken || null)
            state.accessToken = accessToken || null
            state.refreshToken = refreshToken || null
            window.localStorage.setItem('accessToken', accessToken || null)
            window.localStorage.setItem('refreshToken', refreshToken || null)
        },
        setPhoneNumber(state: AuthState, { phone } : { phone: string }) {
          state.phone = phone
        },
        setEmail (state: AuthState, { email } : { email: string}) {
            state.email  =email
        },
        resetTokens(state: AuthState) {
            auth.setRefreshToken(null)
            auth.setAccessToken(null)
            state.accessToken = null
            state.refreshToken = null
            window.localStorage.removeItem('accessToken')
            window.localStorage.removeItem('refreshToken')
        },
        deactivateUserSecurity (state: AuthState) {
            state.isUserSecurityActivated = false
        },
        activateUserSecurity (state: AuthState) {
            state.isUserSecurityActivated = true
        },
        setTokensRefreshState (state: AuthState, bool: boolean) {
            state.isUserRefreshing = bool
        }
    },

    actions: {
        async refreshSessionTokens({ commit, state }: { commit: Commit, state: AuthState }) {
            try {
                if (state.isUserRefreshing) 
                    return new Promise((resolve, reject) => {
                        refreshCall?.then(({ accessToken, refreshToken }: { accessToken: string, refreshToken: string }) => { 
                            resolve({ accessToken, refreshToken })})
                            .catch((error: any) => reject(error))
                    })

                commit('setTokensRefreshState', true)
                refreshCall = auth.refresh()
                const { accessToken, refreshToken } = await refreshCall
    
                commit('saveTokens', {
                    accessToken,
                    refreshToken
                })
                commit('setTokensRefreshState', false)
                return { accessToken, refreshToken }
            } catch (error: any) {
                commit('setTokensRefreshState', false)
                return Promise.reject(error)
            }
        }
    }
}