import { Space } from '@/types/client'
import { client } from "@ownesthq/owalt"
import { Commit, Dispatch } from 'vuex'

interface SpaceState {
  currentSpace: Space | undefined
  spaces: Space[]
  clientsCount: number
}

export default {
  namespaced: true,

  state: (): SpaceState => ({
    currentSpace: undefined,
    spaces: [],
    clientsCount: 0
  }),

  mutations: {
    setCurrentSpace(state: SpaceState, space: Space | undefined) {
      state.currentSpace = space
      window.localStorage.spaceId = space?._id
    },
    setSpaces(state: SpaceState, spaces: Space[]) {
      state.spaces = spaces
    },
    setClientsCount(state: SpaceState, clientsCount: number) {
      state.clientsCount = clientsCount
    },
    reset(state: SpaceState) {
      state.currentSpace = undefined
      state.spaces = []
      state.clientsCount = 0
    }
  },

  actions: {
    async getSpaces({ commit }: { commit: Commit }) {
      const { spaces, clientCount } = await client.getSpaces()
      const spacesList = spaces.reduce((acc: any, brand: any) => {
        brand.spaces.forEach((space: any) => {
          space.brandName = brand.name
        })

        return [
          ...acc,
          ...brand.spaces
        ]
      }, [])
      commit('setSpaces', spacesList)
      commit('setClientsCount', clientCount)
    },
    async recoverFromLocalStorage({ state, dispatch, commit }: { state: SpaceState, dispatch: Dispatch, commit: Commit }) {
      await dispatch('getSpaces')
      const savedSpaceId = window.localStorage.getItem('spaceId')
      let savedSpaceFound

      if (savedSpaceId)
        savedSpaceFound = state.spaces.find(space => savedSpaceId === space._id)

      // restore previously saved space
      if (savedSpaceFound) {
        commit('setCurrentSpace', savedSpaceFound)
        return
      }

      // set single space available as default if we have nfts only from the same clients and not from others
      if (state.clientsCount === 1 && state.spaces.length === 1) {
        commit('setCurrentSpace', state.spaces[0])
        return
      }

      // set Owalt space as default
      commit('setCurrentSpace', undefined)
    }
  }
}