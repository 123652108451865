export default {
  "pages": {
    "title": {
      "my-collections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minhas coleções"])},
      "activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atividades"])},
      "messaging": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensagens"])},
      "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfil"])},
      "spaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espaços"])},
      "gallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Galeria"])}
    },
    "login": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O aplicativo mais simples para gerenciar seus Coleccionáveis digitais ⭐️"])}
    }
  },
  "buttons": {
    "login": {
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vamos lá!"])}
    }
  },
  "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceitar"])},
  "contact-permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precisamos da sua permissão para acessar seus contatos."])},
  "share-contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partilhar seus contatos"])},
  "share-my-contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partilhar meus contatos"])},
  "do-not-share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não partilhar"])},
  "address-copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço copiado para a área de transferência"])},
  "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erro"])},
  "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiado"])},
  "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baixar"])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sim"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não"])},
  "see-all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver tudo"])},
  "no-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não há dados para mostrar nesta seção."])},
  "enter-received-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digite o código recebido no seu telefone"])},
  "invalid-code-try-again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código inválido. Por favor, tente novamente."])},
  "resend-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reenviar código"])},
  "retry-in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você pode tentar novamente em:"])},
  "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anterior"])},
  "received-by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recebido por"])},
  "sent-by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviado por"])},
  "recipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destinatário:"])},
  "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resumo"])},
  "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar"])},
  "fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxas de transação:"])},
  "biometric-authentication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autenticação biométrica"])},
  "confirm-identity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, confirme sua identidade"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Início"])},
  "collections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coleções"])},
  "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurações"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesquisar"])},
  "no-collection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não há coleções para mostrar"])},
  "accept-by-clicking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ao clicar em “Começar” você aceita a"])},
  "privacy-policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["política de privacidade"])},
  "and-the": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e os"])},
  "GCU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["termos de uso"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrição:"])},
  "collection": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coleção:"])},
    "blockchain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blockchain"])},
    "supply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elementos"])},
    "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O Criador"])}
  },
  "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criado por:"])},
  "receivedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recebido em:"])},
  "tokenId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de série:"])},
  "attributes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atributos"])},
  "date-unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data desconhecida"])},
  "receive-nft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receber uma coleção digital"])},
  "show-qr-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostre este código QR para receber uma coleção digital"])},
  "select-nft-to-send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qual(is) artigo(s) você gostaria de enviar?"])},
  "no-nft-yet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você ainda não possui nenhum Coleccionáveis digitais."])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Próximo"])},
  "is-sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviado!"])},
  "will-receive-soon": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Foi enviado, o destinatário receberá o NFT em breve"]), _normalize(["Foi enviado, o destinatário receberá os NFTs em breve"])])},
  "contact-permission-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precisamos da sua permissão para acessar seus contatos. Você poderá enviar um Coleccionáveis digitais para alguém da sua lista de contatos sem precisar inserir manualmente o número de telefone dele(a)."])},
  "send-nft-amount": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Enviar meu NFT para"]), _normalize(["Enviar meus NFTs para"])])},
  "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contatos"])},
  "blockchain-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço blockchain"])},
  "enter-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserir um endereço"])},
  "enter-phone-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserir um número de telefone"])},
  "standard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Padrão"])},
  "fast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rápido"])},
  "immediate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imediato"])},
  "scan-qr-please": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, escaneie o QRCode."])},
  "no-recent-activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma atividade recente no momento."])},
  "sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviado"])},
  "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recebido"])},
  "updated-on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atualizado em:"])},
  "congrats-received-nft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parabéns, você recebeu um NFT! 🎉"])},
  "discover-nft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descobrir meu NFT"])},
  "authorize-biometrics-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para garantir a segurança da sua Owalt e fazer login mais rapidamente, precisamos que você autorize a autenticação biométrica (impressão digital ou reconhecimento facial)."])},
  "authorize-biometrics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorizar autenticação biométrica"])},
  "my-wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minha carteira"])},
  "recently-received-nft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recebidos recentemente"])},
  "no-nft-received-recently": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você não recebeu nada recentemente"])},
  "receive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receber"])},
  "account-deletion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exclusão da conta"])},
  "account-deletion-are-you-sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tem certeza de que deseja excluir sua conta?"])},
  "thirty-days-before-account-deletion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você tem 30 dias para recuperá-la antes da exclusão definitiva."])},
  "lost-nfts": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["O NFT será perdido"]), _normalize(["Os NFTs serão perdidos"])])},
  "account-deletion-sure-about-nfts-lost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você tem certeza de que deseja excluir sua conta e perder os NFTs em sua carteira?"])},
  "keep-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eu mantenho minha conta"])},
  "i-delete-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eu excluo minha conta"])},
  "sorry-see-you-go": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lamentamos vê-lo partir"])},
  "account-soon-deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sua conta será excluída em breve"])},
  "account-deletion-thirty-days-left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você tem 30 dias para recuperá-la. Após, ela será excluída permanentemente."])},
  "errors": {
    "errorOccured": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocorreu um erro, tente novamente"])},
    "oops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ops...!"])}
  },
  "my-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minha conta:"])},
  "safety": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segurança:"])},
  "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suporte:"])},
  "terms-and-conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termos e condições de uso"])},
  "privacy-policy-setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Política de privacidade"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sair"])},
  "contact-authorization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorização de contato"])},
  "biometric-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dados biométricos"])},
  "reconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reconectar"])},
  "do-not-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não permitir"])},
  "contact-permission-required-example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exemplo: você deseja enviar seu NFT para um amigo que tem o número de telefone, mas você não sabe de cor? Basta selecionar o nome dele na lista de contatos para poder enviá-lo."])},
  "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalhes"])},
  "actions": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ações"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar"])}
  },
  "add-memory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar uma foto"])},
  "authenticity-certificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificado de autenticidade e garantia"])},
  "repair-request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pedido de reparo"])},
  "my-pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minhas fotos"])},
  "block-nft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bloquear o NFT"])},
  "loss-or-theft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Em caso de perda ou roubo"])},
  "make-opposition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fazer oposição"])},
  "declare-nft-lost": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Você quer declarar o NFT do seu ", _interpolate(_named("nftName")), " perdido ou roubado? O NFT será bloqueado."])},
  "add-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar detalhes"])},
  "sure-make-opposition": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Você tem certeza de que deseja bloquear o NFT do seu ", _interpolate(_named("nftName")), "?"])},
  "it-is-a-mistake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isso é um erro"])},
  "yes-block-nft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sim, quero bloqueá-lo"])},
  "withdraw-opposition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retirar minha oposição"])},
  "nft-will-be-unblocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O NFT será desbloqueado e o relatório de perda/roubo será retirado."])},
  "nft-blocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este NFT está bloqueado"])},
  "private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privado"])},
  "public": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Público"])},
  "no-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você não recebeu nenhuma mensagem"])},
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carregando..."])},
  "page-number": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["página"]), _normalize(["páginas"])])},
  "show-all-pages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar todas as páginas"])},
  "take-picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tirar uma foto"])},
  "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validar"])},
  "give-memory-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dê um título para sua foto"])},
  "file-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Título do arquivo"])},
  "add-file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar arquivo"])},
  "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoje"])},
  "week-of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semana de"])},
  "no-gallery-picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você ainda não salvou nenhuma foto para este NFT"])},
  "from-gallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Da minha galeria"])},
  "email": {
    "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digite seu e-mail"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Associar um e-mail à sua conta Owalt"])},
    "myAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minha conta:"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail de confirmação"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foi enviado, você receberá um e-mail de confirmação."])},
    "myEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meu e-mail:"])},
    "errorConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocorreu um erro, seu e-mail não pôde ser validado"])},
    "alreadyUsed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este e-mail já está em uso"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])}
  },
  "enter-received-code-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digite o código recebido por e-mail"])},
  "confirmMyEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar"])},
  "warrantyCertificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificado de garantia"])},
  "manufacturerWarranty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garantia do fabricante: 2 anos"])},
  "proofOfOwnership": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prova de propriedade"])},
  "detainedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detido por:"])},
  "ref": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ref:"])},
  "model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modelo:"])},
  "dateOfPurchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data da compra:"])},
  "proofOfAuthenticity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prova de autenticidade"])},
  "holderPassport-Halchimy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você é o titular do passaporte"])},
  "blocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bloqueado"])},
  "exchange-with-client": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Trocar com ", _interpolate(_named("client"))])},
  "messaging": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensagens"])},
  "history": {
    "transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferência NFT"])},
    "opposition-Halchimy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bloqueio do passaporte"])},
    "repair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pedido de manutenção"])},
    "addImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adição de uma foto pública"])},
    "endRepair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manutenção concluída"])},
    "button": {
      "seeRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver pedido"])},
      "seePhoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver foto"])}
    },
    "withdrawOpposition-Halchimy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desbloqueio do passaporte"])},
    "creation-Halchimy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criação do passaporte"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Histórico: "])},
    "transfer-Halchimy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferência de passaporte"])},
    "creation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criação do NFT"])},
    "withdrawOpposition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desbloqueio do NFT"])},
    "opposition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bloqueio do NFT"])}
  },
  "product": {
    "details": {
      "buttons": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrição"])},
        "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Histórico"])},
        "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ações"])}
      },
      "label": {
        "tokenId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de série"])},
        "tokenId-Halchimy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passaporte digital"])}
      }
    }
  },
  "wish-you-delete-picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deseja excluir esta foto?"])},
  "are-you-sure-delete-picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta ação é irreversível. Tem certeza de que deseja excluir esta foto?"])},
  "picture-edition-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fotos"])},
  "private-picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privada"])},
  "public-picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pública"])},
  "picture-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de foto"])},
  "add-picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar foto"])},
  "repair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reparo"])},
  "repairRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pedido de reparo"])},
  "repairInProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reparo em andamento"])},
  "repairFinished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reparo concluído"])},
  "searchCity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesquisar cidade"])},
  "sendRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar solicitação"])},
  "deposeMyProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deixar meu produto"])},
  "finishedRepair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reparo concluído"])},
  "requestSend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitação enviada"])},
  "appointmentRepairCenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agendar visita ao centro de reparo indicado"])},
  "repairCenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centro de reparo:"])},
  "addDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicione uma descrição do problema"])},
  "with-certified-repairer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Com um reparador certificado"])},
  "see-my-current-request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver minha solicitação atual:"])},
  "authenticity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autenticidade:"])},
  "qr-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código QR"])},
  "submitProductModal": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deixar meu produto"])},
    "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Você delega a responsabilidade para <strong>", _interpolate(_named("store")), "</strong>, e deverá apresentar a prova de propriedade para recuperar seu produto."])},
    "deposeMyProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deixar meu produto"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não, ainda não estou no centro"])}
  },
  "back-home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voltar à página inicial"])},
  "mint-in-progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criação em progresso..."])},
  "changeMyEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alterar meu e-mail"])},
  "is-confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmado"])},
  "mint-on-my-wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criado em minha carteira"])},
  "minted-on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criado em"])},
  "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proprietário"])},
  "scan-product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escanear o código QR de um produto"])},
  "scan-qrcode-target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aponte para o código QR para selecionar o produto"])},
  "scan-qrcode-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O código QR não é reconhecido pelo aplicativo."])},
  "addressBC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereços de Blockchain"])},
  "contact-unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contato desconhecido"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conexão:"])},
  "not-specified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não especificado"])},
  "notifications-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notificações push"])},
  "add-to-contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar aos contatos"])},
  "add-a-contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar um contato"])},
  "give-contact-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, forneça o nome para salvar o contato"])},
  "contact-added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contato adicionado"])},
  "contact-added-name": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " foi adicionado à sua lista de contatos"])},
  "forms": {
    "fields": {
      "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
      "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobrenome"])}
    }
  },
  "serialNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de série:"])},
  "externalWallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carteira externa"])},
  "contact-search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digite um nome, um número de telefone"])},
  "refuse-fingerprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não usar"])},
  "certified-ethereum-blockchain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificado na blockchain Ethereum"])},
  "block-nft-Halchimy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bloquear o passaporte"])},
  "declare-nft-lost-Halchimy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Você deseja declarar o passaporte do seu ", _interpolate(_named("nftName")), " como perdido ou roubado? O passaporte será bloqueado."])},
  "sure-make-opposition-Halchimy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Você tem certeza de que deseja bloquear o passaporte do seu ", _interpolate(_named("nftName")), "?"])},
  "nft-blocked-Halchimy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este passaporte está bloqueado"])},
  "no-gallery-picture-Halchimy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você ainda não salvou uma foto para este passaporte"])},
  "nft-will-be-unblocked-Halchimy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O passaporte será desbloqueado e o relato de perda/roubo será retirado."])},
  "discover-nft-Halchimy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descobrir meu passaporte"])},
  "congrats-received-nft-Halchimy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parabéns, você recebeu um passaporte digital! 🎉"])},
  "send-nft-amount-Halchimy": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Enviar meu passaporte"]), _normalize(["Enviar meus passaportes"])])},
  "will-receive-soon-Halchimy": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Está enviado, o destinatário receberá o passaporte em breve"]), _normalize(["Está enviado, o destinatário receberá os passaportes em breve"])])},
  "tokenId-Halchimy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passaporte digital:"])},
  "holderPassport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você é o titular do NFT"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefone"])},
  "search-contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procurar um contato ou número de telefone"])},
  "public-pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fotos públicas"])},
  "cancel-repair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar pedido"])},
  "cancel-repair-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deseja excluir este pedido de manutenção?"])},
  "cancel-repair-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta ação é irreversível."])},
  "accept-by-clicking-received-nft": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ao clicar em \"", _interpolate(_named("buttonText")), "\", você aceita o"])},
  "recaptcha": {
    "conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este aplicativo é protegido pelo reCAPTCHA (<a href=\"https://policies.google.com/privacy\" target=\"_blank\">política de privacidade</a> - <a href=\"https://policies.google.com/terms\" target=\"_blank\">termos de uso</a>)."])}
  },
  "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tentar novamente"])},
  "send-invalid-address-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocorreu um erro ao enviar. O número de telefone, endereço ou nome ENS do destinatário pode não existir."])},
  "pending-transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Em transferência"])},
  "performed-service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serviço realizado"])},
  "service-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de serviço"])},
  "send-invalid-address-typing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço inválido"])},
  "weeks-ago": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Esta semana"]), _normalize(["Semana passada"]), _normalize(["Há ", _interpolate(_named("count")), " semanas"])])},
  "mint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mint"])},
  "collectibles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Os meus coleccionáveis"])},
  "no-nft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum NFT para exibir"])},
  "new-messages": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " Nova mensagem"]), _normalize([_interpolate(_named("count")), " Novas mensagens"])])},
  "concerned-nfts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NFTs relacionados"])},
  "today-at": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hoje às ", _interpolate(_named("time"))])},
  "nfts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nfts"])},
  "profile": {
    "my-badges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meus emblemas"])}
  },
  "no-badges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum Badges para exibir"])},
  "exchanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negociações"])},
  "new-user-alias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novo pseudo"])},
  "spaces": {
    "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O escolha o seu espaço"])},
    "selectmain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceder à sua wallet"])}
  },
  "congrats-received-nft-homeety": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parabéns, você recebeu um NFT! 🎉"])},
  "discover-nft-homeety": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descobrir meu NFT"])},
  "send-nft-amount-homeety": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Enviar os NFT"]), _normalize(["Enviar os NFTs"])])},
  "will-receive-soon-homeety": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Está enviado, o destinatário receberá o NFT em breve"]), _normalize(["Está enviado, o destinatário receberá os NFTs em breve"])])},
  "block-nft-homeety": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bloquear o NFT"])},
  "load-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver mais"])},
  "update-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["É hora de atualizar o aplicativo!"])},
  "update-available-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uma nova versão do nosso aplicativo está disponível na loja."])},
  "GCU-update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Os nossos Termos e Condições estão a mudar"])},
  "GCU-update-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para poder continuar a aceder à sua conta Owalt, aceite-os."])},
  "read-GCU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ler os termos e condições"])},
  "privacy-policy-update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A nossa política de privacidade está a evoluir"])},
  "privacy-policy-update-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para continuar a aceder à sua conta Owalt, aceite este acordo."])},
  "read-privacy-policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ler a política de privacidade"])},
  "actuality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notícias"])},
  "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ou"])},
  "show-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saber mais"])},
  "resend-code-whatsap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "enter-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserir um e-mail"])},
  "owalt": {
    "confirm": {
      "delete": {
        "communication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
      }
    }
  },
  "downloadAndInstall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["baixar e instalar"])}
}