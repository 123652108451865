import { createStore } from 'vuex'

import auth from './modules/auth'
import collections from './modules/collections'
import transactions from './modules/transactions'
import products from './modules/products'
import users from './modules/users'
import messages from './modules/messages'
import permissions from './modules/permissions'
import badges from './modules/badges'
import spaces from './modules/spaces'
import news from './modules/news'

interface MainState {
  pageTransition: {
    name: string
    mode: string
  }
  isPullRefreshing: boolean
  isHomeNftReception: boolean
  previousRouteNameFromNftDetails: string
  previousRouteParamsFromNftDetails: any
  previousRouteNameFromCollectionDetails: string
  previousRouteNameFromSelectNft: string
  isMobileScreenSize: boolean
}

const mainState: MainState = {
  pageTransition: {
    name: "router-view",
    mode: "out-in"
  },
  isPullRefreshing: false,
  isHomeNftReception: false,
  isMobileScreenSize: false,
  previousRouteNameFromNftDetails: '',
  previousRouteParamsFromNftDetails: {},
  previousRouteNameFromCollectionDetails: '',
  previousRouteNameFromSelectNft: '',
}

export default createStore({
  state: () => (mainState),
  getters: {
    nameTransition: (state: MainState) => state.pageTransition.name,
    modeTransition: (state: MainState) => state.pageTransition.mode
  },
  mutations: {
    setPageTransition(state: MainState, transitions) {
      state.pageTransition = transitions
    },
    setIsPullRefreshing(state: MainState, boolean) {
      state.isPullRefreshing = boolean
    },
    setIsHomeNftReception(state: MainState, bool: boolean) {
      state.isHomeNftReception = bool
    },
    setPreviousRouteNameFromNftDetails(state: MainState, routeName: string) {
      state.previousRouteNameFromNftDetails = routeName
    },
    setPreviousRouteParamsFromNftDetails(state: MainState, params: any) {
      state.previousRouteParamsFromNftDetails = params
    },
    setPreviousRouteNameFromCollectionDetails(state: MainState, routeName: string) {
      state.previousRouteNameFromCollectionDetails = routeName
    },
    setPreviousRouteNameFromSelectNft(state: MainState, routeName: string) {
      state.previousRouteNameFromSelectNft = routeName
    },
    setIsMobileScreenSize(state: MainState, bool: boolean) {
      state.isMobileScreenSize = bool
    }
  },
  actions: {
    async logout({ commit, dispatch }) {
      dispatch('resetUserData')
      commit('auth/resetTokens')
    },
    async resetUserData({ commit, dispatch }) {
      commit('permissions/pauseNotifications')
      commit('users/reset')
      commit('transactions/reset')
      dispatch('products/reset')
      dispatch('collections/reset')
      commit('messages/reset')
      commit('badges/reset')
      commit('spaces/reset')
    },

    transition({ commit }: any, { value }: { value: string }) {
      if (value === 'default') commit('setPageTransition', { name: 'router-view', mode: 'out-in' })
      else if (value === 'footer') commit('setPageTransition', { name: '', mode: '' })
      else commit('setPageTransition', { name: 'router-view-back', mode: '' })
    }
  },
  modules: {
    auth,
    collections,
    transactions,
    products,
    users,
    messages,
    permissions,
    badges,
    spaces,
    news,
  }
})
