
import { defineComponent, defineAsyncComponent, onMounted, ref, watch, computed, provide } from 'vue'
import { useRouter, useRoute } from "vue-router"
import { useStore } from "vuex"
import { useI18n } from 'vue-i18n'

/* Owalt */
import { auth } from '@ownesthq/owalt'
import { legal } from '@ownesthq/owalt'

/* Utils and plugins */
import loginManager from './mixins/loginManager'
import { showFingerprint } from '@/plugins/auth'
import { getPlatform, getStoreUrl, getVersion, isAndroidApplication, isApplication, isIosApplication } from './utils/platforms'
// import { disconnectSocket, initSocket } from './plugins/socket'
import languageManager from '@/mixins/languageManager'

/* Modals */
const LegalsModal = defineAsyncComponent(() => import ('@/components/modals/LegalsModal.vue'))
const LoginModal = defineAsyncComponent(() => import ('@/components/modals/LoginModal.vue'))
const UpdateVersionModal = defineAsyncComponent(() => import ('@/components/modals/UpdateVersionModal.vue'))

// To fix ts errors
declare let window: any
declare let universalLinks: any

// loader
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import axios from 'axios'

export default defineComponent({
  name: 'App',
  components: {
    LoginModal,
    LegalsModal,
    UpdateVersionModal,
    Loading
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const { t } = useI18n()
    const { restoreLocaleFromLocalStorage } = languageManager()
    const { 
      privacyPolicyText,
      termsAndConditionsText,
      isPrivacyPolicyModalShown,
      isTermsAndConditionsModalShown,
      handleOpenTermsAndConditionsModal,
      handleOpenPrivacyPolicyModal,
      handleCloseTermsAndConditionsModal,
      handleClosePrivacyPolicyModal,
    } = loginManager()

    const waitFingerprint = ref<boolean>(true)
    const isLoginModalShown = ref<boolean>(false)
    const isLoading = ref<boolean>(false)
    const isUpdateVersionModalShown = ref<boolean>(false)
    const isForceUpdateModal = ref<boolean>(false)

    /* --------------------------- COMPUTED --------------------------- */
    const isUserLoggedIn = computed(() => store.getters['auth/loggedIn'])
    const currentSpace = computed(() => store.state.spaces.currentSpace)

    /* ----------------------------- LIFE CYCLE ----------------------------- */
    onMounted(async () => {
      provide('isLoading', { isLoading, updateIsLoading })
      // define isMobile
      defineScreenSize()
      window.addEventListener('resize', defineScreenSize)

      // set the last used locale
      restoreLocaleFromLocalStorage()

      // on app lauch, the login page will prompt the biometrics validation to login directly if refreshToken is still valid
      // TODO: do it during the splashScreen
      router.isReady().then(async () => {
        if (!isApplication()) {
          await checkAuth()
          watch(
            () => route.name,
            async () => {
              if (!isUserLoggedIn.value) {
                await checkAuth()
              }
            }
          )
        }
      })
    })

    document.addEventListener("deviceready", async () => {
      if (isApplication()) {
        await store.dispatch('permissions/initPermissions')
        router.isReady().then(async () => {
          await checkAuth()
          await checkVersion()
        })
      }

      universalLinks.subscribe(null, function (eventData: any) {
        router.push({
          path: eventData.path,
          query: eventData.params
        })
      })
    })

    document.addEventListener("resume",
      async () => {
        // update permissions (user could have change them in phone settings)
        if (!isApplication()) return
        await store.dispatch('permissions/initPermissions')
      },
      false
    )

    document.addEventListener("visibilitychange", () => {
      // interrupt loader if we bring it back forward and api calls stopped
      if (document.hidden) isLoading.value = false
      if (isApplication() && store.state.auth.isUserSecurityActivated) checkAuth()
    })

    /* ---------------------------------------- METHODS ---------------------------------------- */

    const hideLoginModal = () => {
      isLoginModalShown.value = false
    }

    const defineScreenSize = () => {
      store.commit('setIsMobileScreenSize', window.innerWidth < 1200)
    }

    const handleCloseUpdateVersionModal = () => {
      isUpdateVersionModalShown.value = false
    }

    const handleShowUpdateVersionModal = () => {
      isUpdateVersionModalShown.value = true
    }
    const handleUpdateUpdateVersionModal = () => {
      return window.location.href = getStoreUrl()
    }

    const checkVersion = async() => {
      try {
        const platform = getPlatform()
        if ((!isAndroidApplication() && !isIosApplication()) || !platform) throw new Error('platform not supported.')
        const versionsRes = await axios.get(
          process.env.VUE_APP_DISTANT_VERSION_URL
        )
        if (versionsRes) console.log(versionsRes)
        const appUserVersion = await getVersion()
        const appVersion = versionsRes?.data[platform]?.version
        const appForceUpdateVersion = versionsRes?.data[platform]?.forceUpdateVersion
        if (appUserVersion < appVersion) {
          if (appUserVersion < appForceUpdateVersion) {
            isForceUpdateModal.value = true
          }
          isUpdateVersionModalShown.value = true
        }
      } catch(error) {
        console.log(error)
        isUpdateVersionModalShown.value = false
      }

    }

    const showLoginModalOrRedirect = async () => {
      if (route.meta?.public) return
      if (route.name === 'home') {
        router.push({ name: 'login' })
        return
      }

      const [conditionsRes, privacyRes] = await Promise.all([
        legal.getTermsAndConditions("Owalt"),
        legal.getPrivacyPolicy("Owalt"),
      ])
      privacyPolicyText.value = privacyRes.data.text
      termsAndConditionsText.value = conditionsRes.data.text
      isLoginModalShown.value = true
    }

    const checkAuth = async () => {
      if (document.hidden || !!route.query.token || store.state.auth.isUserRefreshing)
        return

      store.commit('auth/initFromLocalStorage')
      const accessToken = store.state.auth.accessToken
      const refreshToken = store.state.auth.refreshToken

      // route?.meta?.public can not be in the first condition, because it prevents froom being logged back in the app on app relauch 
      if ((!accessToken || !refreshToken)) {
        await showLoginModalOrRedirect()
        waitFingerprint.value = false
        return
      }

      const isBiometricsAllowed = store.state.permissions.isBiometricsAllowed
      let isRefreshTokenValid = false
      let isAccessTokenValid = false

      auth.setRefreshToken(refreshToken)
      auth.setAccessToken(accessToken)

      isRefreshTokenValid = auth.hasValidRefreshToken()
      isAccessTokenValid = auth.hasValidAccessToken()

      // no valid token or no biometric authentication available
      if (!isRefreshTokenValid && !isAccessTokenValid) {
        await showLoginModalOrRedirect()
        waitFingerprint.value = false
        return
      }

      // no need to refresh token, just check that the user is the phone user
      if (isAccessTokenValid) {
        try {
          await showFingerprint({ cancelButton: t('reconnect') })
          store.dispatch('users/fetch')
          if (isApplication()) await store.dispatch('permissions/initNotifications')
        } catch (error: any) {
          console.log(error)
          if (!((!isBiometricsAllowed || ['BIOMETRIC_UNAVAILABLE', 'BIOMETRIC_PERMISSION_NOT_GRANTED', 'BIOMETRIC_NOT_ENROLLED', 'Not allowed to use biometric identification'].includes(error.message)))) {
            store.commit('auth/resetTokens')
            await showLoginModalOrRedirect()
            return
          }
          if (isApplication()) await store.dispatch('permissions/initNotifications')
        } finally {
          waitFingerprint.value = false
        }
        // refresh accessToken because it has expired
      } else if (!isAccessTokenValid && isRefreshTokenValid) {
        try {
          await showFingerprint({ cancelButton: t('reconnect') })
          await store.dispatch('auth/refreshSessionTokens')
          if (isApplication()) await store.dispatch('permissions/initNotifications')
        } catch (error: any) {
          if ((!isBiometricsAllowed || ['BIOMETRIC_UNAVAILABLE', 'BIOMETRIC_PERMISSION_NOT_GRANTED', 'BIOMETRIC_NOT_ENROLLED', 'Not allowed to use biometric identification'].includes(error.message))) {
            try {
              await store.dispatch('auth/refreshSessionTokens')
              if (isApplication()) await store.dispatch('permissions/initNotifications')
            } catch (error) {
              store.commit('auth/resetTokens')
              await showLoginModalOrRedirect()
              return
            }
          } else {
            store.commit('auth/resetTokens')
            await showLoginModalOrRedirect()
            return
          }
          console.log(error)
        } finally {
          waitFingerprint.value = false
        }
      }
    }

    const updateIsLoading = (boolean: boolean) => {
      isLoading.value = boolean
    }

    /* ---------------------------------------- WATCHERS ---------------------------------------- */

    watch(
      () => store.state.auth.accessToken,
      accessToken => {
        if (!accessToken) {
          // disconnectSocket()
          return
        }

        store.dispatch('users/fetch')
        // TODO replace socket
        // initSocket()
      }, { immediate: true }
    )

    watch(
      () => route.query,
      async (query: any) => {
        const token = query.token
        const productId = query.productId?.toString()
        
        store.commit('auth/initFromLocalStorage')
        const currentAccessToken = store.state.auth.accessToken
        const currentRefreshToken = store.state.auth.refreshToken
        
        if (!token) return

        waitFingerprint.value = true

        try {
          // login the new user with the given token
          const { accessToken, refreshToken } = await auth.login({ token })
          store.commit('auth/saveTokens', {
            accessToken,
            refreshToken
          })
          
          // reset former user data
          store.dispatch('resetUserData')

          // remove the token from query params
          await router.replace({
            name: 'home', params: {
              receivedNFT: productId
            }
          })
          checkAuth()
        } catch {
          /* token is expired */

          // no previous user
          if (!currentAccessToken || !currentRefreshToken) {
            // disconnectSocket()
            showLoginModalOrRedirect()
            waitFingerprint.value = false
            return
          }

          // re-log former user
          await router.replace({ name: 'home', params: {} })
          checkAuth()
        }
      }
    )

    watch(currentSpace, async () => {
      if (currentSpace.value)
        document.body.classList.add('withSpace')
      else
        document.body.classList.remove('withSpace')
    }, { deep: true })

    return {
      isLoading,
      isLoginModalShown,
      privacyPolicyText,
      termsAndConditionsText,
      isTermsAndConditionsModalShown,
      isPrivacyPolicyModalShown,
      waitFingerprint,
      hideLoginModal,
      handleOpenTermsAndConditionsModal,
      handleOpenPrivacyPolicyModal,
      handleCloseTermsAndConditionsModal,
      handleClosePrivacyPolicyModal,
      handleCloseUpdateVersionModal,
      handleUpdateUpdateVersionModal,
      handleShowUpdateVersionModal,
      isUpdateVersionModalShown,
      isForceUpdateModal
    }
  }
})
